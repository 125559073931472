import React from 'react';
import {
  AlertIcon,
  PrimaryButtonWrapper,
  Description,
  Wrapper,
  Title
} from '../../../../global-styles/global-styled-components';
import COLORS, { ICONS } from '../../../../utils/styles';
import { theme } from './styled-components';
import Button from '../../Button';

const TaxFormBanner = ({ account, editPayeeAccountAction, userId }) => (
  <Wrapper theme={theme} customStyle="margin-left:100px;">
    <AlertIcon icon={ICONS.EXCLAMATION_CIRCLE_HOLLOW} theme={theme} color={COLORS.denimBlue} />
    <div>
      <Title theme={theme}>1099 TAX Submission Options</Title>
      <Description theme={theme}>
        Annual tax forms are currently filed through STRIPE EXPRESS accounts - which include the
        parent/guardian information for officials under 18 years old.
      </Description>
      <Description theme={theme}>
        For added flexibility and to offer 1099s for users of all ages, Stack Officials can now
        collect and process individual taxpayer information. Complete the steps below by{' '}
        <b>December 31st</b>, to receive your tax form via Stack Officials instead of Stripe.
      </Description>
      <Description theme={theme}>
        Tax forms currently available through Stripe can be found here:
      </Description>
      <PrimaryButtonWrapper center="xs" theme={theme}>
        <Button
          type="submit"
          width="160px"
          height="32px"
          label="1099 FORMS VIA STRIPE"
          primary
          onClick={() =>
            editPayeeAccountAction({
              id: account.id,
              userId
            })
          }
        />
      </PrimaryButtonWrapper>
    </div>
  </Wrapper>
);

export default TaxFormBanner;
