import React from 'react';
import { Field } from 'react-final-form';
import CollapsibleContent from '../../../CollapsableContent';
import RoundedCheckBox from '../../../RoundedCheckBox';
import { pxToRem } from '../../../../../utils/helpers';
import { COLORS } from '../../../../../utils/styles';
import {
  Content,
  OptionContainer,
  Option,
  OfficalSelfAssignOption,
  InnerWrapper,
  InputOuterWrapper,
  InputWrapper,
  Description
} from './styled-components';
import baseCollapsibleTheme from '../config';
import { OptionLabel } from '../NotificationCollapsible/styled-components';
import { NumericField } from '../NotificationCollapsible';
import { valueInRange } from '../../../../../utils/validations';
import ConditionalField from '../../../ConditionalField';

const OfficalsSelfAssignCollapsibleTheme = {
  ...baseCollapsibleTheme,
  headerMinPaddingTop: `${pxToRem('32px')}`
};

const OfficalsSelfAssignCollapsible = ({ disabled }) => (
  <CollapsibleContent
    title="OFFICIALS SELF ASSIGN"
    theme={OfficalsSelfAssignCollapsibleTheme}
    externalIsOpen
  >
    <Content>
      <OptionContainer>
        <OfficalSelfAssignOption textColor={COLORS.shuttleGray}>
          <InnerWrapper>
            <Option>
              <Field name="official_self_assign_setting.checked" disabled={disabled}>
                {({ input, meta, ...rest }) => <RoundedCheckBox {...input} {...rest} />}
              </Field>
              <OptionLabel color={COLORS.shuttleGray}>
                Enable self assign (allow officials to assign themselves to games if they meet the
                minimum grade or rank requirements for the selected game and crew position).{' '}
              </OptionLabel>
            </Option>
            <ConditionalField when="official_self_assign_setting.checked" predicate={type => !type}>
              {selfAssignEnabled => (
                <InputOuterWrapper>
                  <Option>
                    <Field
                      name="official_self_assign_setting.disable_complete_pay_profile_requirement_checked"
                      disabled={selfAssignEnabled}
                    >
                      {({ input, meta, ...rest }) => (
                        <RoundedCheckBox {...input} {...rest} locked={selfAssignEnabled} />
                      )}
                    </Field>
                    <OptionLabel color={COLORS.shuttleGray}>
                      <b style={{ 'margin-left': '.75rem' }}>
                        Pay profile NOT required for self assignment
                      </b>{' '}
                    </OptionLabel>
                  </Option>
                  <Description>
                    Allow volunteers and other officials to self assign without requiring payment
                    info.
                  </Description>
                  <InputWrapper>
                    <NumericField
                      validate={valueInRange({ min: 1, max: 999 })}
                      min={1}
                      max={999}
                      name="official_self_assign_setting.max_games_per_day"
                      disabled={selfAssignEnabled}
                      defaultValue={null}
                      label="Maximum games an official can assign themselves per day"
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <NumericField
                      validate={valueInRange({ min: 1, max: 999 })}
                      min={1}
                      max={999}
                      name="official_self_assign_setting.max_games_per_24h"
                      disabled={selfAssignEnabled}
                      defaultValue={null}
                      label="Maximum games an official can assign themselves within 24 hours - no matter the game date"
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <NumericField
                      validate={valueInRange({ min: 0, max: 999 })}
                      min={0}
                      max={999}
                      name="official_self_assign_setting.block_days_before_game"
                      disabled={selfAssignEnabled}
                      defaultValue={null}
                      label="Number of days before a game’s date in which officials are blocked from self assigning"
                    />
                    <Description>
                      For example, if 3 is entered then an official will be blocked from assigning
                      themselves to a game, when the game’s date is within 2 or less days.
                    </Description>
                  </InputWrapper>

                  <InputWrapper>
                    <NumericField
                      validate={valueInRange({ min: 1, max: 999 })}
                      min={1}
                      max={999}
                      name="official_self_assign_setting.allow_days_before_game"
                      disabled={selfAssignEnabled}
                      defaultValue={null}
                      label="Number of days before a game’s date in which officials can begin self assigning"
                    />
                    <Description>
                      For example, if 31 is entered then an official can only assign themselves to
                      games if the game date is within 30 days or less of today’s date. If the game
                      is out 31 days or more, then the official cannot assign themselves to the
                      game.
                    </Description>
                  </InputWrapper>
                </InputOuterWrapper>
              )}
            </ConditionalField>
          </InnerWrapper>
        </OfficalSelfAssignOption>
      </OptionContainer>
    </Content>
  </CollapsibleContent>
);

export default OfficalsSelfAssignCollapsible;
