import { compose, pure, withHandlers, withProps, withState } from 'recompose';
import { connect } from 'react-redux';
import FileSaver from 'file-saver';

import { formatDate } from '../../../utils/parsers';
import { paginationAware, updateOnPageChange, filterConfigToNames } from '../PaginationAware';
import {
  fetchGames as fetchGamesAction,
  storeGamesEvent as storeGamesEventAction,
  fetchGamesEventCrewLabels as fetchGamesEventCrewLabelsAction,
  createGameAssignment,
  deleteGameAssignments as deleteGameAssignmentsAction,
  updateGames as updateGamesAction,
  deleteBulkGames as deleteBulkGamesAction,
  updateGameAssignments as updateGameAssignmentsAction,
  setCurrentGame as setCurrentGameAction,
  submitGamesImport as submitGamesImportAction,
  submitGamesUpdate as submitGamesUpdateAction,
  setPaginationPage,
  submitCopiedGameId
} from '../../../core/Games';
import {
  setSelectedReportsEvent as setSelectedReportsEventAction,
  storeReportsEventAndRedirect as storeReportsEventAndRedirectAction
} from '../../../core/Reports';
import {
  fetchEvents as fetchEventsAction,
  fetchEventGameLevels,
  fetchFilteredEvents as fetchFilteredEventsAction,
  fetchEventInfoReportSettings,
  fetchEventUsersByUserIdAction,
  clearEventUsersDataStateAction
} from '../../../core/Events';
import {
  setShowModalFor as setShowModalForAction,
  unsetShowModalFor as unsetShowModalForAction
} from '../../../core/Modals';
import {
  addNotifications as addNotificationsAction,
  setAssignmentWarnings as setAssignmentWarningsAction,
  updateNotification as updateNotificationAction
} from '../../../core/Notifications';
import {
  isGamesEventOfficialOrAssessor,
  isGamesEventOfficial,
  getUser,
  isGamesEventAdminOrAssignor,
  getUserIndex,
  isGamesEventAdmin,
  getTimezonesList
} from '../../../core/Auth/selectors';
import {
  getGames,
  getGameMeta,
  getGamesEvent,
  getGamesEventCrewLabels,
  getGamesEventId,
  isGamesEventGroupAdmin,
  getCurrentCopiedGameFlag
} from '../../../core/Games/selectors';

import generateFields from '../../components/Games/Header/GamesFilter/config';
import {
  getEvents,
  getEventGameLevels,
  getEventCategories,
  isGameUploadTypeManual,
  getFilteredEvents,
  isOfficialSelfAssignAllowed,
  getEventAssessmentReportSettings,
  getEventReportSettings,
  getEventDisableGameDeclineSettings,
  getEventUsers
} from '../../../core/Events/selectors';

import unconnected from './unconnected';
import { getAssignmentAceeptanceUser, getSelectedUser } from '../../../core/Users/selectors';
import PublishAssignmentsModal from '../../components/Games/Modals/PublishAssignmentsModal';
import DeleteGamesModal from '../../components/Games/Modals/DeleteGamesModal';
import ProgressGameDeletionModal from '../../components/Games/Modals/ProgressGameDeletionModal';
import PublishGamesModal from '../../components/Games/Modals/PublishGamesModal';
import UnpublishGamesModal from '../../components/Games/Modals/UnpublishGamesModal';
import AcceptAssignmentsModal from '../../components/Games/Modals/AcceptAssignmentsModal';
import DeclineAssignmentsModal from '../../components/Games/Modals/DeclineAssignmentsModal';
import IncidentReportsModal from '../../components/Games/Modals/IncidentReportsModal';
import GameReportModal from '../../components/Games/Modals/GameReportModal';
import { sendBulkEmail, setAssignmentAceeptanceUser } from '../../../core/Users';
import GameStatusChangeModal from '../../components/Games/Modals/GameStatusChangeModal';
import { fetchUserSchedule as fetchUserScheduleAction } from '../../../core/User';
import { getTeams } from '../../../core/Teams/selectors';
import { getLocations } from '../../../core/Locations/selectors';

const mapStateToProps = state => ({
  gamesEvent: getGamesEvent()(state),
  crewLabels: getGamesEventCrewLabels(state),
  events: getEvents(state),
  games: getGames(state),
  gameMeta: getGameMeta(state),
  canEdit: isGamesEventGroupAdmin()(state) || isGamesEventAdminOrAssignor(state),
  canDecline: isGamesEventOfficialOrAssessor(state),
  selectedUser: getSelectedUser(state),
  gamelevels: getEventGameLevels(state),
  categories: getEventCategories(state),
  isGameUploadTypeManual: isGameUploadTypeManual(getGamesEventId(state), state),
  allowSelfAssignment:
    isGamesEventOfficial(state) &&
    isOfficialSelfAssignAllowed(state) &&
    !(isGamesEventGroupAdmin()(state) || isGamesEventAdminOrAssignor(state)),
  user: getUser(state),
  reportSettings: getEventReportSettings(state),
  eventsAssessmentSettings: getEventAssessmentReportSettings(state),
  isFetching: state.games.isFetching,
  filteredEvents: getFilteredEvents(state),
  eventDisableGameDeclineSettings: getEventDisableGameDeclineSettings(state),
  userIdx: getUserIndex(state),
  isGamesEventAdmin: isGamesEventAdmin(state),
  isGamesEventGroupAdmin: isGamesEventGroupAdmin()(state),
  timezonesList: getTimezonesList(state),
  isCopiedGameIdCorrect: getCurrentCopiedGameFlag(state),
  isFetchingCopiedGameId: state.games && state.games.isFetchingCopiedGameId,
  userAssignments: getAssignmentAceeptanceUser(state),
  currentEventUsers: getEventUsers(state),
  teams: getTeams(state),
  complexes: getLocations(state)
});

const mapDispatchToProps = dispatch => ({
  fetchGames: payload => dispatch(fetchGamesAction(payload)),
  fetchGamesEventCrewLabels: () => dispatch(fetchGamesEventCrewLabelsAction()),
  storeGamesEvent: payload => dispatch(storeGamesEventAction(payload)),
  fetchEvents: payload => dispatch(fetchEventsAction(payload)),
  fetchEventUsersCalendarData: payload => dispatch(fetchEventUsersByUserIdAction(payload)),
  clearEventUsersData: payload => dispatch(clearEventUsersDataStateAction(payload)),
  fetchEventGameLevels: payload => dispatch(fetchEventGameLevels(payload)),
  createAssignment: payload => dispatch(createGameAssignment(payload)),
  deleteGameAssignments: payload => dispatch(deleteGameAssignmentsAction(payload)),
  updateGames: payload => dispatch(updateGamesAction(payload)),
  deleteBulkGames: payload => dispatch(deleteBulkGamesAction(payload)),
  updateGameAssignments: payload => dispatch(updateGameAssignmentsAction(payload)),
  setAssignmentWarnings: payload => dispatch(setAssignmentWarningsAction(payload)),
  setCurrentGame: payload => dispatch(setCurrentGameAction(payload)),
  setSelectedReportsEvent: payload => dispatch(setSelectedReportsEventAction(payload)),
  setShowModalFor: payload => dispatch(setShowModalForAction(payload)),
  unsetShowModalFor: payload => dispatch(unsetShowModalForAction(payload)),
  storeReportsEventAndRedirect: payload => dispatch(storeReportsEventAndRedirectAction(payload)),
  submitGamesImport: payload => dispatch(submitGamesImportAction(payload)),
  submitGamesUpdate: payload => dispatch(submitGamesUpdateAction(payload)),
  submitCopiedGameId: payload => dispatch(submitCopiedGameId(payload)),
  sendEmail: payload => dispatch(sendBulkEmail(payload)),
  fetchFilteredEvents: payload => dispatch(fetchFilteredEventsAction(payload)),
  fetchEventInfoReportSettings: payload => dispatch(fetchEventInfoReportSettings(payload)),
  setPaginationPage: payload => dispatch(setPaginationPage(payload)),
  updateNotification: payload => dispatch(updateNotificationAction(payload)),
  fetchUserSchedule: payload => dispatch(fetchUserScheduleAction(payload)),
  setAssignmentAceeptanceUser: payload => dispatch(setAssignmentAceeptanceUser(payload)),
  addNotifications: payload => dispatch(addNotificationsAction(payload))
});

export const PAGINATION_AWARE_PREFIX = 'gm';
export const GAMES_DOWNLOAD_LIMIT = 1500;

const downloadCSV = ({ fetchGames, filter, gameMeta, addNotifications, byGame = true }) => () => {
  if (gameMeta && gameMeta.total_count > GAMES_DOWNLOAD_LIMIT) {
    addNotifications([
      {
        type: 'error',
        message: 'Please apply filters to reduce the number of games to below 1500.'
      }
    ]);
  } else {
    // this is brittle because need refactor if other file content formats added
    const file_name = byGame ? 'game' : 'assignment';
    new Promise(resolve =>
      fetchGames({ toCSV: true, resolve, filters: filter && filter.filterData, byGame })
    ).then(({ data }) => {
      const blob = new Blob([data], { type: 'text/csv' });
      FileSaver.saveAs(blob, `${file_name}_list_${formatDate(new Date(), 'YYYYMMDDTHHmmss')}.csv`);
    });
  }
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('gameImportNotification', 'setGameImportNotification', true),
  paginationAware(
    {
      fetcherQuote: 'fetchGames',
      forPrefix: PAGINATION_AWARE_PREFIX,
      metaQuote: 'gameMeta',
      searchFilter: ['game.id'],
      filterNames: filterConfigToNames(generateFields({}))
    },
    updateOnPageChange(PAGINATION_AWARE_PREFIX)
  ),
  withHandlers({
    downloadGames: ({ fetchGames, filter, gameMeta, addNotifications }) =>
      downloadCSV({ fetchGames, filter, gameMeta, addNotifications }),
    downloadAssignments: ({ fetchGames, filter, gameMeta, addNotifications }) =>
      downloadCSV({ fetchGames, filter, gameMeta, addNotifications, byGame: false })
  }),
  withProps({
    PublishAssignmentsModal,
    DeleteGamesModal,
    ProgressGameDeletionModal,
    PublishGamesModal,
    UnpublishGamesModal,
    AcceptAssignmentsModal,
    DeclineAssignmentsModal,
    IncidentReportsModal,
    GameReportModal,
    GameStatusChangeModal
  }),
  unconnected,
  pure
);
