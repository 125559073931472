import { Col } from 'react-flexbox-grid';
import styled from 'styled-components';
import { Container } from '@material-ui/core';
import { pxToRem } from '../../../../utils/helpers';

export const NoteWrapper = styled(Col)`
  margin-top: ${pxToRem('10px')};
  width: 500;
`;

export const NoteContainer = styled(Container)`
  background-color: rgb(211, 251, 221);
  border-radius: 3px;
`;

export const NoteLabel = styled(Col)`
  width: 70;
  padding: 8px 0 0 3px;
  font-family: 'Barlow';
  ${({ isHeading }) => (isHeading ? 'font-weight: bold;' : '')}
  font-size: ${pxToRem('13px')};
`;

export const theme = {
  primary_button: {
    margin_left: `${pxToRem('80px')}`,
    margin_top: `${pxToRem('10px')}`
  },
  icon: {
    float: 'left',
    width: `${pxToRem('15px')}`,
    height: `${pxToRem('15px')}`,
    margin_top: `${pxToRem('10px')}`,
    margin_left: 0,
    margin_right: `${pxToRem('10px')}`,
    padding_right: 0
  }
};
