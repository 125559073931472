import { compose, pure, lifecycle, withState } from 'recompose';

export default compose(
  withState('showDeliveryOptionsModal', 'setShowDeliveryOptionsModal', false),
  withState('isFetchingTaxInfo', 'setIsFetchingTaxInfo', false),
  withState(
    'currentDeliveryMethod',
    'setCurrentDeliveryMethod',
    props =>
      (props.taxData &&
        props.taxData.delivery_type !== undefined &&
        props.taxData.delivery_type.toString()) ||
      undefined
  ),
  withState(
    'first',
    'setFirstName',
    props => (props.taxData && props.taxData.data && props.taxData.data.first) || null
  ),
  withState(
    'middle',
    'setMiddleName',
    props => (props.taxData && props.taxData.data && props.taxData.data.middle) || null
  ),
  withState(
    'last',
    'setLastName',
    props => (props.taxData && props.taxData.data && props.taxData.data.last) || null
  ),
  withState('ssn', 'setSsn', ''),
  lifecycle({
    componentDidMount() {
      const { paramId, getTaxDataDispatch } = this.props;
      if (paramId) {
        getTaxDataDispatch({ user_id: paramId });
      }
    },
    componentDidUpdate(preProps) {
      const {
        taxData,
        setFirstName,
        setMiddleName,
        setLastName,
        setSsn,
        setCurrentDeliveryMethod
      } = this.props;
      const { taxData: prevtaxData } = preProps;
      if (JSON.stringify(taxData) !== JSON.stringify(prevtaxData)) {
        setCurrentDeliveryMethod(
          (taxData && taxData.delivery_type !== undefined && taxData.delivery_type.toString()) ||
            undefined
        );
        setFirstName((taxData && taxData.data && taxData.data.first) || null);
        setMiddleName((taxData && taxData.data && taxData.data.middle) || null);
        setLastName((taxData && taxData.data && taxData.data.last) || null);

        if (!taxData || !taxData.data || !taxData.data.last4) setSsn(null);
      }
    }
  }),
  pure
);
