import { pxToRem } from '../../../../utils/helpers';
import COLORS from '../../../../utils/styles';

// eslint-disable-next-line import/prefer-default-export
export const theme = {
  div: {
    font_family: 'Roboto',
    font_size: `${pxToRem('12px')}`,
    font_weight: 500,
    text_align: 'left',
    color: `${COLORS.black}`,
    border: `${pxToRem('1px')} solid #d8dee4`,
    border_radius: `${pxToRem('15px')}`,
    border_color: `${COLORS.denimBlue}`,
    line_height: `${pxToRem('20px')}`,
    padding_left: `${pxToRem('30px')}`,
    box_shadow: `0 0 8px rgb(0 0 0 / 30%)`,
    width: '60%'
  },
  icon: {
    cursor: 'pointer',
    float: 'left',
    width: `${pxToRem('35px')}`,
    height: `${pxToRem('35px')}`,
    margin_top: `${pxToRem('8px')}`,
    margin_left: 0,
    margin_right: 0,
    padding_right: 0
  },
  description: {
    margin_left: `${pxToRem('20px')}`,
    padding_right: `${pxToRem('20px')}`
  },
  title: {
    margin_left: `${pxToRem('20px')}`,
    padding_right: `${pxToRem('20px')}`
  },
  primary_button: {
    margin_right: `${pxToRem('80px')}`,
    margin_bottom: `${pxToRem('10px')}`
  }
};
