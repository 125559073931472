/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Box, Tab, Tabs, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Dialog } from '../../../../global-material-components';
import Card from './paymentMethods/card';
import ACHAccount from './paymentMethods/ach-account';
import { CardWrapper, ACHWrapper } from './styled-components';

const useStyles = makeStyles({
  AddPaymentMethod: {
    overflowY: 'auto',
    maxHeight: '420px'
  }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box>{children}</Box>
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export default function AddPaymentMethod({
  addModal,
  closeAddModalHandler,
  addPaymentSource,
  addNotifications,
  dialogDisplay,
  setDialogDisplay
}) {
  const classes = useStyles();
  const [activeTab, setActiveTab] = React.useState(0);

  /* Tab Active */
  function handleChangeTab(_, newValue) {
    setActiveTab(newValue);
  }
  return (
    <Dialog
      id="addPaymentMethodDialog"
      title="Add your Payment Method"
      onClose={() => {
        closeAddModalHandler();
      }}
      open={addModal}
      noCancel
      noPrimary
      style={{ display: dialogDisplay }}
    >
      <div>
        <Tabs
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChangeTab}
          aria-label="add Account details"
        >
          <Tab label="Bank Accounts" {...a11yProps(0)} />
          <Tab label="Cards" {...a11yProps(1)} />
        </Tabs>

        <>
          <TabPanel value={activeTab} index={0} classes={{ root: classes.AddPaymentMethod }}>
            <ACHWrapper>
              <ACHAccount
                addBank={addPaymentSource}
                closeModal={closeAddModalHandler}
                addNotifications={addNotifications}
                dialogDisplay={dialogDisplay}
                setDialogDisplay={setDialogDisplay}
              />
            </ACHWrapper>
          </TabPanel>
          <TabPanel value={activeTab} index={1} classes={{ root: classes.AddPaymentMethod }}>
            <CardWrapper>
              <Card
                addCard={addPaymentSource}
                closeAddModalHandler={closeAddModalHandler}
                addNotifications={addNotifications}
              />
            </CardWrapper>
          </TabPanel>
        </>
      </div>
    </Dialog>
  );
}
