/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import { BodyText } from '../../../../global-material-components';
import AddPaymentMethod from './AddPaymentMethod';
import LoaderWrapper from '../../../LoaderWrapper';
import CollapsableContent from '../../../CollapsableContent';
import {
  CenteredContentContainer,
  FlexRow,
  TextBorder,
  AddBtn,
  RowsMarginZero,
  InnerFormCol,
  AccountText,
  ContainerPadding,
  SectionHeading
} from './styled-components';
import PaymentSources from './PaymentView/PaymentSources';
import Wallet from '../../../Wallet';

export default function DirectDeposit({
  userId,
  isFetching,
  paymentMethods,
  addPaymentSource,
  deletePaymentSource,
  addNotifications,
  currentUserId,
  dialogDisplay,
  setDialogDisplay,
  writeAccess
}) {
  const [addModal, setAddModal] = React.useState(false);

  function closeAddModalHandler() {
    setAddModal(false);
  }

  function AddCardHandler() {
    const addPaymentMethodId = document.getElementById('addPaymentMethodDialog');
    closeAddModalHandler(true);
    setTimeout(function () {
      if (addPaymentMethodId) {
        setDialogDisplay('block');
      }
      setAddModal(true);
    }, 200);
  }

  const paymentSources = paymentMethods
    ? paymentMethods.reduce((acc, method) => {
        if (!acc[method.method]) acc[method.method] = [];
        acc[method.method].push(method);
        return acc;
      }, {})
    : {};

  return (
    <ContainerPadding>
      <RowsMarginZero>
        <InnerFormCol xs={12}>
          <CollapsableContent title="FUNDING OPTIONS" pl={0} isOpen={1} setOpen externalIsOpen>
            <AccountText color="#484848" marginL="0.8rem" marginT="0.8rem" display="block">
              Create or edit payment methods below:
            </AccountText>
            <CenteredContentContainer>
              <FlexRow>
                <BodyText margin="0px 0px 20px 0px">
                  <RowsMarginZero between="lg">
                    <Col>
                      <SectionHeading>Payment Method: </SectionHeading>
                    </Col>
                    <Col>
                      <AddBtn disabled={!writeAccess} onClick={AddCardHandler}>
                        {' '}
                        Add{' '}
                      </AddBtn>
                    </Col>
                  </RowsMarginZero>
                  <TextBorder>
                    {addModal && (
                      <AddPaymentMethod
                        addModal={addModal}
                        closeAddModalHandler={closeAddModalHandler}
                        addPaymentSource={addPaymentSource}
                        addNotifications={addNotifications}
                        dialogDisplay={dialogDisplay}
                        setDialogDisplay={setDialogDisplay}
                      />
                    )}
                  </TextBorder>
                  <LoaderWrapper isFetching={isFetching}>
                    <PaymentSources
                      paymentSources={paymentSources}
                      isFetching={isFetching}
                      deletePaymentSource={deletePaymentSource}
                      userId={userId}
                      currentUserId={currentUserId}
                      writeAccess={writeAccess}
                    />
                  </LoaderWrapper>
                </BodyText>
              </FlexRow>
              <FlexRow>
                <BodyText>
                  <Row between="lg">
                    <Col xs={12}>
                      <SectionHeading>Wallet</SectionHeading>
                    </Col>
                    <Col xs={12}>
                      <Wallet showAddFundsBtn wideView readOnly={!writeAccess} />
                    </Col>
                  </Row>
                </BodyText>
              </FlexRow>
            </CenteredContentContainer>
          </CollapsableContent>
        </InnerFormCol>
      </RowsMarginZero>
    </ContainerPadding>
  );
}

DirectDeposit.propTypes = {
  isFetching: PropTypes.bool.isRequired
};
