export const withFetch = (state, value) => {
  const newState = { ...state };
  if (value) {
    newState.fetching = value;
  } else {
    delete newState.newState;
  }
  return newState;
};

class EnhancedState {
  constructor(enhancers) {
    this.state = null;
    const self = this;
    Object.keys(enhancers).forEach(enhancer => {
      self[enhancer] = value => enhancers[enhancer](self.state, value);
    });
  }

  set(state) {
    this.state = { ...state };
    return this;
  }

  flush() {
    this.state = null;
    return this;
  }
}

export const enhanceState = enhancers => new EnhancedState(enhancers);

export const Uint8ArrayToDownloadPDF = (arrayBuffer, file_name) => {
  const blob3 = new Blob([arrayBuffer], { type: 'application/pdf' });
  const url = window.URL.createObjectURL(blob3); // Create a downloadable URL
  const a = document.createElement('a'); // Create a hidden anchor element
  a.href = url;
  a.download = `${file_name.replace(/\s+/g, '_')}.pdf`; // Set the filename and removing spaces
  a.style.display = 'none';
  document.body.appendChild(a); // Append to the DOM
  a.click(); // Trigger the download
  a.remove(); // Clean up
  window.URL.revokeObjectURL(url); // Release the URL
};
