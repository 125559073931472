import { allAvailableProvinces } from '../../../../../constants';
import { billingMethodOptions, capitalize, sortListByKey } from '../../../../../utils/helpers';
import { normalizePhone, returnOnlyNumbers } from '../../../../../utils/parsers';
import { LeftDropDownError } from '../../../MaterialDropDown';
import MaterialInput from '../../../MaterialInput';
import {
  composeValidators,
  isValidAreaCode,
  minLength,
  required
} from '../../../../../utils/validations';
import MaterialUiDropDown from '../../../../global-material-components/MaterialUiDropdown';
import MaterialUiTypehead from '../../../../global-material-components/MaterialUiTypehead';

const getplaceholderRequired = (message, flag) => (flag ? `${message}*` : message);
const addNew = 'Add New';
const getTaxPayerOptions = billingCustomers => {
  const customers = [];
  const modifyCustomer =
    billingCustomers &&
    billingCustomers.length &&
    billingCustomers.map(billing_customer => ({
      value: billing_customer.id,
      name: `${capitalize(billing_customer.billing_name)} - ${billing_customer.tax_id}`
    }));
  let result = [];
  result = modifyCustomer && modifyCustomer.length ? customers.concat(modifyCustomer) : [];
  const events = result && result.length ? sortListByKey(result, 'name') : [];
  return events;
};

export default {
  getTaxesFields: ({
    billingMethodId,
    billingCustomers,
    setEventBillingModal,
    setUpdateTaxInfoModal,
    mutators,
    isGamesEventGroupAdmin,
    tax_info,
    hasStagingBatch,
    setUpdatedTaxInfo
  }) => [
    {
      field: {
        component: MaterialUiDropDown,
        name: 'info.required_payment_profile',
        placeholder: getplaceholderRequired(
          billingMethodId
            ? 'Will this event pay officials online - via Stack Officials?'
            : 'Will this event pay officials online - via Stack Officials?*',
          billingMethodId
        ),
        validate: isGamesEventGroupAdmin ? required : '',
        options: billingMethodOptions,
        fieldError: LeftDropDownError,
        disabled: !isGamesEventGroupAdmin || hasStagingBatch,
        extraNotes:
          'Note: S-Pay Inc/Stack Officials will file 1099-NEC tax forms that combine earnings from all events.',
        showExtraNotes: value => value === 1
      }
    },
    ...(billingMethodId
      ? [
          {
            field: {
              component: MaterialUiDropDown,
              name: 'tax_info',
              helpText: getplaceholderRequired('Who is responsible for funding the payments?*'),
              options: getTaxPayerOptions(billingCustomers),
              showError: true,
              fieldError: LeftDropDownError,
              validate: isGamesEventGroupAdmin ? required : '',
              defaultValue: '',
              optionButton: addNew,
              onButtonClick: () => {
                setEventBillingModal(true);
                mutators.setTaxInfoDropdown(tax_info);
              },
              onOptionClick: selectedValue => {
                const [payerInfo] = billingCustomers.filter(
                  billingCustomer => billingCustomer.id === selectedValue
                );
                setUpdatedTaxInfo(payerInfo);
                setUpdateTaxInfoModal(true);
              },
              disabled: !isGamesEventGroupAdmin || hasStagingBatch,
              theme: {
                listHeight: '200px'
              }
            }
          }
        ]
      : [])
  ],

  taxPayerModalFields: ({ openModal, hasInvoice }) => [
    {
      field: {
        component: MaterialInput,
        name: 'billing_name',
        placeholder: 'Payer’s Name *',
        validate: openModal ? required : '',
        disabled: hasInvoice,
        theme: {
          input: `&:disabled{background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 26%, rgb(217 217 217) 28%, rgb(217 217 217) 100%)}`
        }
      }
    },
    {
      field: {
        component: MaterialInput,
        name: 'tax_id',
        alwaysShowError: true,
        placeholder: 'Payer’s Tax ID number (9 digits, usually an EIN)*',
        parse: returnOnlyNumbers,
        validate: openModal
          ? composeValidators(required, minLength(9, 'Expected minimum 9 digits'))
          : '',
        marginTop: '10px',
        maxLength: '9',
        disabled: hasInvoice,
        theme: {
          input: `&:disabled{background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 26%, rgb(217 217 217) 28%, rgb(217 217 217) 100%)}`
        }
      }
    },
    {
      field: {
        component: MaterialInput,
        name: 'address_1',
        placeholder: 'Address 1*',
        validate: openModal ? required : ''
      }
    },
    {
      field: {
        component: MaterialInput,
        name: 'address_2',
        placeholder: 'Address 2',
        parse: x => x
      }
    },
    {
      field: {
        component: MaterialInput,
        name: 'city',
        placeholder: 'City*',
        validate: openModal ? required : ''
      }
    },
    {
      field: {
        component: MaterialUiTypehead,
        name: 'state',
        placeholder: 'State/Province*',
        options: allAvailableProvinces,
        showError: true,
        fieldError: LeftDropDownError,
        validate: openModal ? required : ''
      }
    },
    {
      field: {
        component: MaterialInput,
        name: 'postal_code',
        placeholder: 'Zip/Postal Code*',
        maxLength: '7',
        validate: openModal ? required : ''
      }
    },
    {
      field: {
        component: MaterialInput,
        name: 'phone_number',
        placeholder: 'Payer’s phone number*',
        parse: normalizePhone,
        validate: openModal ? composeValidators(required, minLength(14), isValidAreaCode) : ''
      }
    }
  ]
};
