import React from 'react';
import PropTypes from 'prop-types';

import CheckRadioButton from '../CheckRadioButton';
import { Label, Wrapper, FieldLabel, Option } from './styled-components';
import { requiredValidationError } from '../../../utils/helpers';

/**
 * Built to integrate with React-Final-Form, provides clickable check options (single select)
 * @param {Object} input contains a value and onChange function to update state
 * @param {String} placeholder label for field
 * @param {Array} options collection of option objects with values and names to display
 */
const ChecksField = ({
  input: { value, onChange },
  meta: { error },
  placeholder: label,
  options
}) => {
  const hasError = requiredValidationError(value) && error;
  return (
    <Wrapper>
      <FieldLabel>{label}</FieldLabel>
      {options.map(option => (
        <Option isTaxInfo key={option.name} onClick={() => onChange(option.value)}>
          <CheckRadioButton checked={value === option.value} />
          <Label checked={value === option.value}>{option.name}</Label>
        </Option>
      ))}
      <Label hasError={hasError}>{hasError && `${error}`}</Label>
    </Wrapper>
  );
};

ChecksField.propTypes = {
  placeholder: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  )
};

ChecksField.defaultProps = {
  placeholder: '',
  options: []
};

export default ChecksField;
