import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { compose, pure } from 'recompose';
import { isEmpty, not } from 'ramda';
import { setReferrer as setReferrerAction } from '../../../core/Auth';
import { getUser, getLoggedInUserIndex } from '../../../core/Auth/selectors';
import { PRIVATE_URL } from '../../../core/routes';
import * as paths from '../../../core/paths';
import { sInterpolator as routeBuilder } from '../../../utils/helpers';
import { getCurrentStorage, userIdxGetter } from '../../../utils/auth';
import Spinner from '../../components/Spinner';

const isRoot = ({ pathname }) => pathname === '/';

// TODO: This needs refinement.
export const AuthRoute = ({
  user,
  isPrivate,
  location = { pathname: paths.LOGIN },
  setReferrer,
  userIndex,
  ...props
}) => {
  const isLoggedIn = not(isEmpty(user));
  // Not authenticated -> Login
  if (isPrivate && !isLoggedIn) {
    // TODO:Root cause of URL bug.
    // TODO:Will refine this functionality
    // save the current location in order to redirect after login
    if (getCurrentStorage().length === 0) {
      // TODO:Root cause of URL bug.
      // if (!equals(location.pathname, paths.LOGIN)) setReferrer(location.pathname);
      return <Redirect to={paths.LOGIN} />;
    }

    // User does not exist. Send to first available index
    if (getCurrentStorage().length <= userIdxGetter()) {
      window.location = routeBuilder({ userIdx: 0 }, PRIVATE_URL);
    }

    return <Spinner />;
  }

  // Default to Dashboard if he hits root
  if (isRoot(location) && getCurrentStorage().length > 0) {
    window.location = routeBuilder({ userIdx: 0 }, paths.MAIN);
  } else if (isRoot(location)) {
    // Still returning the <Redirect is necessary to prevent infinite reload issues.
    return <Redirect to={paths.LOGIN} />;
  }

  if (
    location.pathname &&
    ![paths.LOGIN, paths.AUTH, paths.CREATE_ACCOUNT].includes(location.pathname) &&
    !location.pathname.includes('/u')
  ) {
    window.location = `/u/0${location.pathname}`;
  }

  return <Route {...props} />;
};

AuthRoute.propTypes = {
  user: PropTypes.objectOf(Object),
  isPrivate: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired,
  setReferrer: PropTypes.func.isRequired,
  userIndex: PropTypes.number
};

AuthRoute.defaultProps = {
  user: {},
  userIndex: null
};

export default compose(
  connect(
    state => ({
      user: getUser(state),
      userIndex: getLoggedInUserIndex(state)
    }),
    {
      setReferrer: setReferrerAction
    }
  ),
  pure
)(AuthRoute);
