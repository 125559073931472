import React from 'react';
import { Field } from 'react-final-form';
import { Col, Row } from 'react-flexbox-grid';
import { NoteContainer, NoteLabel, NoteWrapper, theme } from './styled-components';
import { pxToRem } from '../../../../utils/helpers';
import TitleBar from '../../TitleBar';
import {
  AlertIcon,
  PrimaryButtonWrapper,
  SubSectionTitle,
  SubSectionWrapper
} from '../../../../global-styles/global-styled-components';
import COLORS, { ICONS } from '../../../../utils/styles';
import Button from '../../Button';
import ChecksField from '../../ChecksField';

export default function TaxInfoStep1Form({
  userId,
  taxData,
  createTaxData,
  updateTaxData,
  clearTaxData,
  setCurrentDeliveryMethod,
  currentDeliveryMethod,
  user,
  isCrossEdit
}) {
  const style = { width: '100%', font: 'Roboto' };
  const options = [
    { name: 'Online Only', value: '0' },
    { name: 'Online and Via US mail', value: '1' }
  ];

  const onCheckboxChange = input => {
    setCurrentDeliveryMethod(input);
  };

  const handleSubmit = () => {
    const newTaxData = {
      ...taxData,
      delivery_type: currentDeliveryMethod,
      user_id: userId,
      account_type: '0'
    };

    // Generate delivery_option_selected_at
    const now = new Date();
    if (!newTaxData.data) newTaxData.data = {};
    newTaxData.data.delivery_option_selected_at = now.toISOString();

    const deliveryTypeMatch = options.find(option => option.value === currentDeliveryMethod);
    if (deliveryTypeMatch && user && user.profile) {
      const name = `${user.profile.first_name} ${user.profile.last_name}`;
      const message = `[${deliveryTypeMatch.name}] Submitted by ${name} ${now.toLocaleDateString(
        'en-US'
      )}`;
      newTaxData.data.step_1_message = message;
    }
    const command = taxData && taxData.id ? updateTaxData : createTaxData;
    command({ tax_data: newTaxData });
  };

  const handleCancel = () => {
    clearTaxData({ tax_data: taxData });
  };

  const input = { value: currentDeliveryMethod, onChange: onCheckboxChange };

  return (
    <form style={style}>
      <TitleBar margin="20px 0px 0px 0px">STEP 1</TitleBar>
      <Row between="xs">
        <SubSectionWrapper xs={14} md={7}>
          <SubSectionTitle>AGREE TO RECEIVE YOUR TAX FORM THROUGH STACK OFFICIALS</SubSectionTitle>
          <Row
            style={{
              marginTop: pxToRem('10px'),
              marginLeft: pxToRem('10px')
            }}
          >
            <Col>
              <Field
                name="step1.deliveryOptions"
                component={ChecksField}
                placeholder="Deliver Tax forms*"
                options={options}
                input={input}
              />
            </Col>
          </Row>
          {taxData && taxData.data && taxData.data.step_1_message && (
            <Row>
              <NoteWrapper>
                <NoteContainer>
                  <AlertIcon
                    icon={ICONS.CHECK_CIRCLE_HOLLOW}
                    fill={COLORS.strongGreen}
                    theme={theme}
                  />
                  <NoteLabel style={{ width: 400, padding: '8px 10px 10px 3px' }}>
                    {taxData.data.step_1_message}
                  </NoteLabel>
                </NoteContainer>
              </NoteWrapper>
            </Row>
          )}
          <Row style={{ marginTop: pxToRem('10px'), marginLeft: pxToRem('10px') }}>
            <Col>
              <PrimaryButtonWrapper left="xs" theme={theme}>
                <Button
                  type="button"
                  width="160px"
                  height="32px"
                  label="Update Delivery Method"
                  primary
                  onClick={handleSubmit}
                  disabled={isCrossEdit || !currentDeliveryMethod}
                />
              </PrimaryButtonWrapper>
            </Col>
            <Col>
              <PrimaryButtonWrapper center="xs" theme={theme}>
                <Button
                  type="button"
                  width="160px"
                  height="32px"
                  label="Revert to Stripe"
                  secondary
                  disabled={isCrossEdit || !taxData || !taxData.id}
                  onClick={handleCancel}
                />
              </PrimaryButtonWrapper>
            </Col>
          </Row>
        </SubSectionWrapper>
      </Row>
    </form>
  );
}
