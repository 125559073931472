import React from 'react';
import { Form } from 'react-final-form';
import { RowWrapper } from './styled-components';
import TaxFormBanner from '../Banner';
import LoaderWrapper from '../../LoaderWrapper';
import TaxInfoStep1Form from '../Step1';
import TaxInfoStep2Form from '../Step2';
import TaxForms from '../TaxForms';

export default function TaxIdentification({
  account: accounts,
  userId,
  editPayeeAccountAction,
  isFetching: isFetchingTaxInfo,
  submitTaxInfo,
  publicKey,
  taxData,
  createTaxData,
  updateTaxData,
  clearTaxData,
  currentDeliveryMethod,
  setCurrentDeliveryMethod,
  user,
  first,
  setFirstName,
  last,
  setLastName,
  middle,
  setMiddleName,
  ssn,
  setSsn,
  isCrossEdit,
  fetchPdf,
  isFetchingTaxReport
}) {
  // there really is at most 1 stripe integrated payee account but the data passed around
  // is a collection ¯\_(ツ)_/¯
  const account = accounts && accounts[0];
  const taxReports = taxData && taxData.reports;

  return (
    <Form
      initialValues={taxData}
      onSubmit={values => {
        submitTaxInfo(values);
      }}
      render={({ values }) => (
        <>
          <RowWrapper>
            <TaxFormBanner
              account={account}
              editPayeeAccountAction={editPayeeAccountAction}
              userId={userId}
            />
          </RowWrapper>
          <LoaderWrapper isFetching={isFetchingTaxInfo}>
            <RowWrapper>
              <TaxInfoStep1Form
                user={user}
                taxData={values}
                createTaxData={createTaxData}
                updateTaxData={updateTaxData}
                clearTaxData={clearTaxData}
                userId={userId}
                setCurrentDeliveryMethod={setCurrentDeliveryMethod}
                currentDeliveryMethod={currentDeliveryMethod}
                isCrossEdit={isCrossEdit}
              />
            </RowWrapper>
            <RowWrapper>
              <TaxInfoStep2Form
                publicKey={publicKey}
                taxData={values}
                updateTaxData={updateTaxData}
                first={first}
                setFirstName={setFirstName}
                last={last}
                setLastName={setLastName}
                middle={middle}
                setMiddleName={setMiddleName}
                ssn={ssn}
                setSsn={setSsn}
                isCrossEdit={isCrossEdit}
              />
            </RowWrapper>
            <RowWrapper>
              <TaxForms
                taxReports={taxReports}
                fetchPdf={fetchPdf}
                isFetchingTaxReport={isFetchingTaxReport}
              />
            </RowWrapper>
          </LoaderWrapper>
        </>
      )}
    />
  );
}
