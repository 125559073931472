import React, { useEffect } from 'react';
import TaxIdentification from './TaxIdentification';

import { Container, Content, ActualContentContainer } from './styled-components';
import Enhancer from '../../containers/TaxInfo';
import { sInterpolator as routeBuilder } from '../../../utils/helpers';
import * as paths from '../../../core/paths';

const TaxInfo = ({
  onSubmit,
  setShowEdit,
  isFetching,
  setAgreementPayload,
  userIdx,
  path,
  user,
  isFundingAdmin,
  currentUserId,
  addPayeeAccountAction,
  editPayeeAccountAction,
  bankAccounts,
  fetchActiveUserInfo,
  pathUrl,
  isSuperAdmin,
  rsaPublic,
  taxData,
  createTaxDataDispatch,
  updateTaxDataDispatch,
  clearTaxDataDispatch,
  setIsFetchingTaxInfo,
  isFetchingTaxInfo,
  setCurrentDeliveryMethod,
  currentDeliveryMethod,
  first,
  setFirstName,
  last,
  setLastName,
  middle,
  setMiddleName,
  ssn,
  setSsn,
  fetchPdf,
  isFetchingTaxReport
}) => {
  const selectUserId = (user && user.id) || currentUserId;
  const isCrossEdit = currentUserId !== (user && user.id);
  const isOnBoardingUrl =
    routeBuilder({ userIdx, selectUserId }, paths.START_PROFILE_PAYMENT_METHOD) === path;
  useEffect(
    () => {
      fetchActiveUserInfo({ userId: selectUserId || currentUserId, pathUrl });
    },
    // eslint-disable-next-line
    []
  );
  const previousBtnPath = routeBuilder(
    { userIdx, selectUserId },
    paths.START_PROFILE_PAYMENT_METHOD
  );
  const skipButtonPath = routeBuilder(
    { userIdx },
    isFundingAdmin ? `${paths.EVENTS}?ev_status=2` : paths.DASHBOARD
  );
  return (
    <>
      <ActualContentContainer isOnBoardingUrl={isOnBoardingUrl}>
        <Container id="taxinfoContainer" isOnBoardingUrl={false}>
          <Content>
            <TaxIdentification
              onSubmit={onSubmit}
              account={bankAccounts}
              userId={selectUserId}
              user={user}
              currentUserId={currentUserId}
              setShowEdit={setShowEdit}
              isFetching={isFetching}
              isSuperAdmin={isSuperAdmin}
              setAgreementPayload={setAgreementPayload}
              isOnBoardingUrl={isOnBoardingUrl}
              previousBtnPath={previousBtnPath}
              skipButtonPath={skipButtonPath}
              addPayeeAccountAction={addPayeeAccountAction}
              editPayeeAccountAction={editPayeeAccountAction}
              publicKey={rsaPublic}
              taxData={taxData}
              createTaxData={createTaxDataDispatch}
              updateTaxData={updateTaxDataDispatch}
              clearTaxData={clearTaxDataDispatch}
              setIsFetchingTaxInfo={setIsFetchingTaxInfo}
              isFetchingTaxInfo={isFetchingTaxInfo}
              setCurrentDeliveryMethod={setCurrentDeliveryMethod}
              currentDeliveryMethod={currentDeliveryMethod}
              first={first}
              setFirstName={setFirstName}
              last={last}
              setLastName={setLastName}
              middle={middle}
              setMiddleName={setMiddleName}
              ssn={ssn}
              setSsn={setSsn}
              isCrossEdit={isCrossEdit}
              fetchPdf={fetchPdf}
              isFetchingTaxReport={isFetchingTaxReport}
            />
          </Content>
        </Container>
      </ActualContentContainer>
    </>
  );
};

export default Enhancer(TaxInfo);
