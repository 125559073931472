import { FormControl } from '@material-ui/core';
import React from 'react';
import { allAvailableProvinces } from '../../../../constants';
import { Input } from '../../../global-material-components';
import { SectionContainer, FormStyles, TileText } from '../style-components';
import { COLORS } from '../../../../utils/styles';
import { requiredValidationError } from '../../../../utils/helpers';

const PaymentFieldSection = ({ handleChange, billingInfo, isEmptypostalCode }) => {
  const stylePostal = {
    borderRadius: '4px',
    border: isEmptypostalCode ? `1px solid ${COLORS.errorRed}` : '1px solid #ccc',
    height: '45px',
    paddingLeft: '15px',
    marginTop: '10px',
    width: '95.5%'
  };

  const styleLabel = {
    position: 'absolute',
    transformOrigin: 'left bottom',
    transform: 'translate(0px, 0.15rem)',
    fontSize: '15px',
    marginLeft: '14px',
    background: '#fff',
    color: isEmptypostalCode ? COLORS.errorRed : 'rgba(0, 0, 0, 0.54)',
    height: '20px',
    padding: '10px 4px',
    zIndex: 99,
    pointerEvents: 'none'
  };

  const errorNum = {
    color: `${COLORS.errorRed}`,
    fontSize: ' 0.7rem',
    position: 'relative',
    boxShadow: 'none !important'
  };

  return (
    <SectionContainer>
      <TileText>Billing Address</TileText>
      <FormStyles>
        <Input
          name="cardHolder.name"
          placeholder="Card Holder Name*"
          label="Card Holder Name"
          onChange={handleChange('name')}
          value={billingInfo.name}
          error={requiredValidationError(billingInfo.name)}
        />
        <div style={errorNum}>
          {requiredValidationError(billingInfo.name) && <span>Required</span>}
        </div>
        <Input
          name="cardHolder.line1"
          id="card-holder-line1"
          placeholder="Address Line 1*"
          label="Address Line 1"
          onChange={handleChange('line1')}
          value={billingInfo.address.line1}
          error={requiredValidationError(billingInfo.address.line1)}
        />
        <div style={errorNum}>
          {requiredValidationError(billingInfo.address.line1) && <span>Required</span>}
        </div>

        <Input
          name="cardHolder.line2"
          placeholder="Address Line 2"
          label="Address Line 2"
          onChange={handleChange('line2')}
          value={billingInfo.address.line2}
        />

        <Input
          name="cardHolder.city"
          placeholder="City*"
          label="City"
          onChange={handleChange('city')}
          value={billingInfo.address.city}
          error={requiredValidationError(billingInfo.address.city)}
        />
        <div style={errorNum}>
          {requiredValidationError(billingInfo.address.city) && <span>Required</span>}
        </div>

        <div style={{ position: 'relative', height: '50px' }}>
          <span id="postalLabel" className="postalLabel" style={styleLabel}>
            PostalCode/Zip
          </span>
          <div id="card-postal" style={stylePostal}>
            <input name="card_postal" label="cardpostal" />
          </div>
        </div>
        <div style={errorNum}>{isEmptypostalCode && <span>Required</span>}</div>
        <br />
        <FormControl error={requiredValidationError(billingInfo.address.state)}>
          <select
            value={billingInfo.address.state}
            onChange={handleChange('state')}
            style={{
              border: `1px solid ${
                !billingInfo.address.state ? COLORS.errorRed : `rgba(0, 0, 0, 0.23)`
              }`
            }}
          >
            {[{ name: 'State*', value: '' }, ...allAvailableProvinces].map(p => (
              <option key={p.value} value={p.value}>
                {p.name}
              </option>
            ))}
          </select>
          <div style={errorNum}>{!billingInfo.address.state && <span>Required</span>}</div>
        </FormControl>
      </FormStyles>
      <br />
    </SectionContainer>
  );
};
export default PaymentFieldSection;
