import { createSelector } from 'reselect';
import { getAllEvents } from '../Events/selectors';

export const getUserAvailabilities = state => state.user.availabilities;

export const getUserAssignments = state => state.user.assignments;

export const getShowBanner = state =>
  (state && state.user.showBanner && state.user.showBanner.showBanner) || false;

export const getTaxInfo = state => (state && state.user.taxInfo) || false;

export const hasTaxInfo = state =>
  state &&
  state.user.profileMeta &&
  state.user.profileMeta.tax_info &&
  state.user.profileMeta.tax_info.id;

export const hasProfileComplete = state =>
  state &&
  state.user.profileMeta &&
  state.user.profileMeta.profile &&
  state.user.profileMeta.profile.completed;

export const payeeStatus = state =>
  state &&
  state.user.profileMeta &&
  state.user.profileMeta.payee &&
  state.user.profileMeta.payee.status;

export const hasPaymentProfileRequired = state =>
  state &&
  state.user.profileMeta &&
  state.user.profileMeta.payment_profile &&
  state.user.profileMeta.payment_profile.required;

export const getCurrentDayAssignmentCount = state => state.user.currentDayAssignmentCount;

export const getPeopleEventId = state => state.user.peopleEventId;

export const getPeopleEvent = () =>
  createSelector(getAllEvents, getPeopleEventId, (events, eventId) =>
    events.find(event => event.id === eventId)
  );

export const getSelfAssignGames = state =>
  state && state.user.selfAssignGames && state.user.selfAssignGames.games;

export const getSelfAssignEvents = state =>
  state && state.user.selfAssignGames && state.user.selfAssignGames.events;

export const getUserSchedule = state => state && state.user && state.user.userSchedule;

export const getUserScheduleCategories = state => {
  const user = state && state.user && state.user.userSchedule;
  return (
    user &&
    user.event_roles &&
    user.event_roles.reduce(
      (categories, role) => [...categories, ...role.categories.map(({ category }) => category)],
      []
    )
  );
};

export const getAvailabilityNoteisFetching = state =>
  state.user && state.user.fetching && state.user.fetching.availabilityNote;

export const getAvailabilityNote = state => state.user && state.user.availabilityNote;

export const getTaxData = state => state && state.user && state.user.tax_data;
