import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import Header from './Header';
import Subheader from '../Subheader';
import GamesList from './GamesList';
import Modal from './GameInfoModal';
import CopyModal from './CopyGameInfoModal ';
import PaginationBar from '../PaginationBar';
import LoaderWrapper from '../LoaderWrapper';
import Enhancer from '../../containers/Games';
import { GameContainer, Wrapper, Overlay } from './styled-components';
import { EmptyMessage } from '../StyledGames/styled-components';
import AL from '../AssignmentsList';
import AssessmentReportModal from '../Reports/Modals/AssessmentReportModal';
import SideListEnhancer from '../../containers/SideList';
import { hasClicked, pxToRem, sortByTime, sortByString, sortByDate } from '../../../utils/helpers';
import CollapsableContent from '../CollapsableContent';
import COLORS from '../../../utils/styles';
import sortingOptions from '../Subheader/GameSorter/config';
import { getCheckedGames } from '../../containers/Games/utils';
import GameImportNotificationModal from './Modals/GameImportNotificationModal';
import { arePropsChanged } from './config';

export const AssignmentsList = SideListEnhancer(AL);

const comparatorMappings = {
  date: sortByDate,
  string: sortByString,
  time: sortByTime
};

export const Games = ({
  games,
  events,
  routes,
  search,
  sort,
  storeGamesEvent,
  gamesEvent,
  crewLabels,
  canEdit,
  canDecline,
  pagination,
  selectedUser,
  filter,
  isVisible,
  toggleVisibility,
  gamelevels,
  categories,
  onAssign,
  onRemoveAssignment,
  setSelectedRoleId,
  selectedRoleId,
  setRoles,
  roles,
  config,
  checkedGames,
  handleCheckboxClick,
  handleSelectAll,
  allowSelfAssignment,
  user,
  isGamesEventGroupAdmin,
  isGamesEventAdmin,
  isGameUploadTypeManual,
  setAssignmentWarnings,
  gamesInProgress,
  handleInProgress,
  isFetching,
  clearParams,
  onAssessmentReportClick,
  onIncidentReportClick,
  onGamesReportClick,
  onCrewVerificationClick,
  showAssessmentReportModal,
  setShowAssessmentReportModal,
  modalEditMode,
  toggleModalEditMode,
  modalCopyGameMode,
  toggleModalCopyGameMode,
  modalProps,
  onSubmitModal,
  onSubmitCopyModal,
  setModalProps,
  confirmModifiedGameLevelModalOpen,
  setConfirmModifiedGameLevelModalOpen,
  setGameLevelChangeConfirmed,
  assignmentFilter,
  handleAssignmentFilter,
  location,
  eventDisableGameDeclineSettings,
  isEventChanged,
  setIsEventChanged,
  calendarActiveMonth,
  setCalendarActiveMonth,
  timezonesList,
  fetchEventUsers,
  fetchEventUsersCalendarData,
  isCopiedGameIdCorrect,
  isFetchingCopiedGameId,
  fetchUserAssignments,
  userAssignments,
  showAcceptErrorAssignmentsModal,
  setShowAcceptErrorAssignmentsModal,
  currentEventUsers,
  teams,
  complexes,
  gameImportNotification,
  setGameImportNotification,
  eventUsersList,
  setEventUsersList,
  usersDemographicsList,
  setUsersDemographicsList,
  clearEventUsersData
}) => {
  const [expandSideList, setExpandSideList] = React.useState(false);
  const [availibiltyTab, setAvailibiltyTab] = React.useState(true);

  useEffect(() => {
    if (allowSelfAssignment) {
      setAvailibiltyTab(false);
    }
  }, [allowSelfAssignment]);

  useEffect(() => {
    toggleModalCopyGameMode(isCopiedGameIdCorrect.flag);
    if (!isCopiedGameIdCorrect.flag) {
      toggleModalEditMode(false);
      setModalProps({});
    }
  }, [isCopiedGameIdCorrect, toggleModalCopyGameMode, setModalProps, toggleModalEditMode]);

  sortingOptions.options.map(option => {
    const optionName = sort.value && sort.value.indexOf('-') > -1 ? `-${option.name}` : option.name;
    if (
      (!sort.value || sort.value === optionName) &&
      Object.prototype.hasOwnProperty.call(option, 'resultSorter')
    ) {
      const isDesc = sort.value ? sort.value.indexOf('-') > -1 : 0;
      const getValue = (obj, key) => {
        const nestedKeys = key.split('.');
        const sortingValue = nestedKeys.reduce((acc, value) => {
          return acc[value];
        }, obj);
        return sortingValue;
      };
      games.sort((a, b) =>
        option.resultSorter.reduce((acc, value) => {
          return (
            acc ||
            comparatorMappings[value.comparator](
              getValue(a, value.key),
              getValue(b, value.key),
              isDesc
            )
          );
        }, null)
      );
    }
    return games;
  });

  return (
    <Wrapper>
      {showAssessmentReportModal && (
        <AssessmentReportModal
          reportId={showAssessmentReportModal.reportId}
          gameId={showAssessmentReportModal.gameId}
          crewLabel={showAssessmentReportModal.crewLabel}
          onClose={() => setShowAssessmentReportModal(null)}
        />
      )}
      {config.action.options.map(
        ({ showModal, onSubmit, component: Component, onClick, label, ...rest }) =>
          showModal && (
            <Component
              key={label}
              onClose={() => onClick(false)}
              selected={getCheckedGames(checkedGames, games)}
              onSubmit={onSubmit}
              userId={user.id}
              canEdit={canEdit}
              allowSelfAssignment={allowSelfAssignment}
              eventDisableGameDeclineSettings={eventDisableGameDeclineSettings}
              fetchUserAssignments={fetchUserAssignments}
              userAssignments={userAssignments}
              showAcceptErrorAssignmentsModal={showAcceptErrorAssignmentsModal}
              setShowAcceptErrorAssignmentsModal={setShowAcceptErrorAssignmentsModal}
              {...rest}
            />
          )
      )}
      <GameContainer
        expand={expandSideList}
        style={(canEdit || allowSelfAssignment) && gamesEvent ? { overflow: 'hidden' } : null}
      >
        <Header
          config={config}
          events={events}
          storeGamesEvent={storeGamesEvent}
          clearParams={clearParams}
          gamesEvent={gamesEvent}
          conditions={{
            gamesSelected: !!checkedGames.length,
            singleGameSelected: checkedGames.length === 1,
            isAuthorized: canEdit,
            canDecline,
            canUploadGames: isGamesEventAdmin || isGamesEventGroupAdmin,
            isGameUploadTypeManual
          }}
          gamelevels={gamelevels}
          categories={categories}
          filter={{ ...filter, filterData: { ...filter.filterData, assignmentFilter } }}
          search={search}
          assignmentFilter={assignmentFilter}
          handleAssignmentFilter={handleAssignmentFilter}
          location={location}
          fetchEventUsers={fetchEventUsers}
          isGamesEventGroupAdmin={isGamesEventGroupAdmin}
          isGamesEventAdmin={isGamesEventAdmin}
          teams={teams}
          complexes={complexes}
          setGameImportNotification={setGameImportNotification}
          setEventUsersList={setEventUsersList}
          setUsersDemographicsList={setUsersDemographicsList}
          clearEventUsersData={clearEventUsersData}
        />

        <Overlay show={isFetching}>
          <LoaderWrapper isFetching />
        </Overlay>
        {gamesEvent && games && games.length ? (
          <>
            <Subheader
              sort={sort}
              labels={crewLabels}
              isVisible={isVisible}
              toggleVisibility={toggleVisibility}
              handleSelectAll={handleSelectAll}
              checked={!!games.length && games.length === checkedGames.length}
              theme={{
                LabelsHeader: games.length > 6 ? 'padding-right: 0.5rem' : 'padding-right: 0rem'
              }}
            />

            <GamesList
              games={games}
              crewLabels={crewLabels}
              selectedUser={selectedUser}
              selectedRoleId={selectedRoleId}
              onAssign={onAssign}
              onRemoveAssignment={onRemoveAssignment}
              handleCheckboxClick={handleCheckboxClick}
              checkedGames={checkedGames}
              canEdit={canEdit}
              allowSelfAssignment={allowSelfAssignment}
              setAssignmentWarnings={setAssignmentWarnings}
              gamesInProgress={gamesInProgress}
              handleInProgress={handleInProgress}
              gamesEventId={gamesEvent && gamesEvent.id}
              gamesEventUploadType={gamesEvent && gamesEvent.game_upload_type}
              categories={categories}
              onAssessmentReportClick={onAssessmentReportClick}
              onIncidentReportClick={onIncidentReportClick}
              onGamesReportClick={onGamesReportClick}
              onCrewVerificationClick={onCrewVerificationClick}
              gameLevels={gamelevels}
              pagination={pagination}
              setModalProps={setModalProps}
              isEventChanged={isEventChanged}
              setIsEventChanged={setIsEventChanged}
              timezonesList={timezonesList}
              eventInfo={gamesEvent}
              currentEventUsers={currentEventUsers}
            />
          </>
        ) : (
          <>
            {(sort.value || search.value || !!Object.keys(filter.filterData).length) && (
              <Subheader
                search={search}
                sort={sort}
                labels={crewLabels}
                isVisible={isVisible}
                toggleVisibility={toggleVisibility}
                handleSelectAll={handleSelectAll}
                checked={!!games.length && games.length === checkedGames.length}
              />
            )}
            <EmptyMessage>
              {gamesEvent
                ? 'No games available'
                : 'You must select an event to display the games and users available for assignment'}
            </EmptyMessage>
          </>
        )}
        {modalProps.gameId && (
          <Modal
            confirmModifiedGameLevelModalOpen={confirmModifiedGameLevelModalOpen}
            setConfirmModifiedGameLevelModalOpen={setConfirmModifiedGameLevelModalOpen}
            setGameLevelChangeConfirmed={setGameLevelChangeConfirmed}
            onClose={e => {
              // the timepicker creates a div outside the modal, this is to prevent the modal from closing when clicking it
              const clickedTimePicker = hasClicked(e, 'rc-time-picker');
              if (!clickedTimePicker) {
                toggleModalEditMode(false);
                toggleModalCopyGameMode(false);
                setModalProps({});
              }
            }}
            modalEditMode={modalEditMode}
            toggleModalEditMode={toggleModalEditMode}
            modalCopyGameMode={modalCopyGameMode}
            toggleModalCopyGameMode={toggleModalCopyGameMode}
            onSubmit={onSubmitModal}
            gameList={gamelevels}
            isGamesEventGroupAdmin={isGamesEventGroupAdmin}
            isGamesEventAdmin={isGamesEventAdmin}
            timezonesList={timezonesList}
            {...modalProps}
          />
        )}
        {modalProps.gameId && modalCopyGameMode && (
          <CopyModal
            onClose={() => {
              toggleModalCopyGameMode(false);
            }}
            modalCopyGameMode={modalCopyGameMode}
            toggleModalCopyGameMode={toggleModalCopyGameMode}
            onSubmit={onSubmitCopyModal}
            gameList={gamelevels}
            timezonesList={timezonesList}
            isCopiedGameIdCorrect={isCopiedGameIdCorrect}
            isFetchingCopiedGameId={isFetchingCopiedGameId}
            confirmModifiedGameLevelModalOpen={confirmModifiedGameLevelModalOpen}
            setConfirmModifiedGameLevelModalOpen={setConfirmModifiedGameLevelModalOpen}
            setGameLevelChangeConfirmed={setGameLevelChangeConfirmed}
            isGameUploadTypeManual={isGameUploadTypeManual}
            {...modalProps}
          />
        )}
        {gamesEvent && games && !!games.length && <PaginationBar {...pagination} />}
      </GameContainer>
      {canEdit && gamesEvent && (
        <>
          <AssignmentsList
            setSelectedRoleId={setSelectedRoleId}
            eventId={gamesEvent.id}
            checkedGames={getCheckedGames(checkedGames, games)}
            setRoles={setRoles}
            roles={roles}
            addClassName="desktop"
            canEdit={canEdit}
            availibiltyTab={availibiltyTab}
            setAvailibiltyTab={setAvailibiltyTab}
            expandSideList={expandSideList}
            setExpandSideList={setExpandSideList}
            calendarActiveMonth={calendarActiveMonth}
            setCalendarActiveMonth={setCalendarActiveMonth}
            selectedRoleId={selectedRoleId}
            eventInfo={gamesEvent}
            complexes={complexes}
            fetchEventUsersCalendarData={fetchEventUsersCalendarData}
            eventUsersList={eventUsersList}
            setEventUsersList={setEventUsersList}
            usersDemographicsList={usersDemographicsList}
            setUsersDemographicsList={setUsersDemographicsList}
          />
          <div className="collapseMobile">
            <LoaderWrapper isFetching={isFetching} theme={{ wrapper: `height:0px` }}>
              <CollapsableContent
                title="Officials/Assessors Assignment"
                pt={1}
                theme={{
                  headerBg: COLORS.denimBlue,
                  headerMinHeight: `8vh`,
                  headerMinPaddingTop: `${pxToRem('0px')}`,
                  iconColorWhenClosed: COLORS.white,
                  iconColorWhenOpen: COLORS.white,
                  titleColorWhenClosed: COLORS.white,
                  titleColorWhenOpen: COLORS.white,
                  justifyContent: 'center'
                }}
                iconStyle={{ margin: '0 0 0 20px', zIndex: 99 }}
              >
                <AssignmentsList
                  setSelectedRoleId={setSelectedRoleId}
                  eventId={gamesEvent.id}
                  checkedGames={getCheckedGames(checkedGames, games)}
                  setRoles={setRoles}
                  roles={roles}
                  canEdit={canEdit}
                  eventInfo={gamesEvent}
                  fetchEventUsersCalendarData={fetchEventUsersCalendarData}
                  eventUsersList={eventUsersList}
                  setEventUsersList={setEventUsersList}
                  usersDemographicsList={usersDemographicsList}
                  setUsersDemographicsList={setUsersDemographicsList}
                />
              </CollapsableContent>
            </LoaderWrapper>
          </div>
        </>
      )}
      {routes.map(({ path, component: Component, role }) => (
        <Route key={path} path={path} render={(...props) => <Component role={role} {...props} />} />
      ))}

      {(isGamesEventGroupAdmin || isGamesEventAdmin) &&
        gamesEvent &&
        gameImportNotification &&
        gamelevels &&
        gamelevels.length &&
        gamelevels.some(gl => !gl.crew_size) && (
          <GameImportNotificationModal
            eventId={gamesEvent.id}
            onClose={() => setGameImportNotification(false)}
          />
        )}
    </Wrapper>
  );
};

Games.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  isEventChanged: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  setIsEventChanged: PropTypes.func.isRequired,
  toggleVisibility: PropTypes.func.isRequired,
  fetchGames: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    onFilter: PropTypes.func,
    onClear: PropTypes.func,
    filterData: PropTypes.objectOf(Object)
  }).isRequired,
  games: PropTypes.arrayOf(Object).isRequired,
  events: PropTypes.arrayOf(Object).isRequired,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.func,
      exact: PropTypes.bool,
      path: PropTypes.string
    })
  ),
  search: PropTypes.shape({
    onSearch: PropTypes.func,
    value: PropTypes.string
  }).isRequired,
  sort: PropTypes.shape({
    onSort: PropTypes.func,
    value: PropTypes.string
  }).isRequired,
  downloadGames: PropTypes.func.isRequired,
  downloadAssignments: PropTypes.func.isRequired,
  gamesEvent: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }),
  storeGamesEvent: PropTypes.func.isRequired,
  canEdit: PropTypes.bool,
  canDecline: PropTypes.bool,
  pagination: PropTypes.shape({
    totalCount: PropTypes.number,
    limit: PropTypes.number,
    pageLinkBuilder: PropTypes.func,
    onLoadPage: PropTypes.func
  }).isRequired,
  crewLabels: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      role_id: PropTypes.number.isRequired
    })
  ),
  selectedUser: PropTypes.shape({
    id: PropTypes.string,
    eventRoleId: PropTypes.string
  }),
  gamelevels: PropTypes.arrayOf(Object),
  onAssign: PropTypes.func.isRequired,
  onRemoveAssignment: PropTypes.func.isRequired,
  setSelectedRoleId: PropTypes.func.isRequired,
  selectedRoleId: PropTypes.number.isRequired,
  roles: PropTypes.shape({
    alias: PropTypes.string
  }).isRequired,
  setRoles: PropTypes.func.isRequired,
  config: PropTypes.shape({
    action: PropTypes.shape({
      options: PropTypes.arrayOf(Object)
    })
  }).isRequired,
  showPublishAssignmentsModal: PropTypes.bool,
  checkedGames: PropTypes.arrayOf(PropTypes.string),
  handleCheckboxClick: PropTypes.func.isRequired,
  handleSelectAll: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(Object),
  isGamesEventGroupAdmin: PropTypes.bool,
  isGameUploadTypeManual: PropTypes.bool,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired,
  gamesInProgress: PropTypes.arrayOf(PropTypes.string),
  handleInProgress: PropTypes.func.isRequired,
  setAssignmentWarnings: PropTypes.func.isRequired,
  clearParams: PropTypes.func.isRequired,
  onAssessmentReportClick: PropTypes.func.isRequired,
  onIncidentReportClick: PropTypes.func.isRequired,
  onGamesReportClick: PropTypes.func.isRequired,
  onCrewVerificationClick: PropTypes.func.isRequired,
  showAssessmentReportModal: PropTypes.shape({
    reportId: PropTypes.number.isRequired,
    gameId: PropTypes.string.isRequired,
    crewLabel: PropTypes.string.isRequired
  }),
  setShowAssessmentReportModal: PropTypes.func.isRequired,
  modalEditMode: PropTypes.bool.isRequired,
  toggleModalEditMode: PropTypes.func.isRequired,
  modalProps: PropTypes.objectOf(Object).isRequired,
  onSubmitModal: PropTypes.func.isRequired,
  setModalProps: PropTypes.func.isRequired,
  confirmModifiedGameLevelModalOpen: PropTypes.bool.isRequired,
  setConfirmModifiedGameLevelModalOpen: PropTypes.func.isRequired,
  setGameLevelChangeConfirmed: PropTypes.func.isRequired,
  assignmentFilter: PropTypes.string.isRequired,
  handleAssignmentFilter: PropTypes.func.isRequired
};

Games.defaultProps = {
  routes: [],
  gamesEvent: null,
  canEdit: false,
  canDecline: false,
  crewLabels: [],
  selectedUser: null,
  gamelevels: [],
  showPublishAssignmentsModal: false,
  checkedGames: [],
  categories: [],
  isGamesEventGroupAdmin: false,
  isGameUploadTypeManual: false,
  gamesInProgress: [],
  showAssessmentReportModal: false
};

export default Enhancer(React.memo(Games, arePropsChanged));
