import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { isEmpty, isNil } from 'ramda';
import Paper from '../../../layouts/Paper';
import TextArea from '../../TextArea';
import Button from '../../Button';
import LoaderWrapper from '../../LoaderWrapper';
import { roundedFormatDate } from '../../../../utils/parsers';
import {
  FormContainer,
  InputsContainer,
  TitleBar,
  HalfContent,
  SectionContainer,
  ButtonContainer
} from './styled-components';
import { ActualContentContainer } from '../styled-components';
import TwoColumnContainer from '../../../layouts/FieldSpreadLayoutContainer';
import GameSettingsCollapsible from './GameSettingsCollapsible';
import config from './config';
import TaxesSection from './TaxesSection';
import BillingSection from './BillingSection';

const formatInitialValues = initialValues => ({
  ...initialValues,
  info: {
    ...initialValues.info,
    ...initialValues.billing,
    season_start_date: roundedFormatDate(initialValues.info.season_start_date, 'YYYY-MM-DD'),
    season_end_date: roundedFormatDate(initialValues.info.season_end_date, 'YYYY-MM-DD'),
    required_payment_profile: initialValues.info.required_payment_profile ? 1 : 0
  }
});

const InformationSection = ({
  sportOptions = [],
  groupOptions = [],
  governingBodies = [],
  isEventGroupAdmin = false
}) => (
  <SectionContainer>
    <TitleBar>Information</TitleBar>
    <TwoColumnContainer
      columns={2}
      fields={config
        .getInformationFields(sportOptions, groupOptions, governingBodies, isEventGroupAdmin)
        .map(({ field, isHalfContent = false }) =>
          isHalfContent ? <HalfContent {...field} /> : <Field {...field} />
        )}
    />
    <InputsContainer>
      <HalfContent>
        <Field component={TextArea} name="info.description" placeholder="description" />
      </HalfContent>
    </InputsContainer>
  </SectionContainer>
);
InformationSection.propTypes = {
  sportOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      name: PropTypes.string
    })
  ).isRequired,
  groupOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      name: PropTypes.string
    })
  ).isRequired,
  governingBodies: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired,
  isEventGroupAdmin: PropTypes.bool.isRequired
};

const getBillingCustomerId = (billingCustomerList, id) => {
  const customer =
    billingCustomerList && billingCustomerList.find(val => Number(val.id) === Number(id));
  return isEmpty(customer) || isNil(customer) ? null : customer.id;
};

export const Information = ({
  onSubmit,
  eventInfo,
  eventBilling,
  eventTaxInfo,
  governingBodies,
  sportsList,
  groupsList,
  isFetching,
  do_games_exist,
  isGamesEventGroupAdmin,
  billingCustomerList,
  isFetchingBillingCustomerList,
  isEventInfoFetching
}) => {
  const { has_invoice: hasInvoice, has_staging_batch: hasStagingBatch } = eventInfo;

  const { status, summary, ...eventInfoProps } = eventInfo; // leave out status and summary
  const initialValues = {
    info: eventInfoProps,
    billing:
      eventBilling && getBillingCustomerId(billingCustomerList, eventBilling.billing_customer_id),
    tax_info:
      eventTaxInfo && getBillingCustomerId(billingCustomerList, eventTaxInfo.billing_customer_id)
  };
  const isEventGroupAdmin =
    groupsList && !!groupsList.find(({ id }) => +id === (eventInfo && eventInfo.group));
  let billingMethodId = true;
  const governingBodyFeatures = governingBodies
    .filter(g => g.value === eventInfo.governing_body)
    .map(f => f.special_features)
    .pop();
  function hasPaymentFeatures() {
    if (governingBodyFeatures === undefined) {
      return false;
    }

    if (!governingBodyFeatures.includes('paying_refs_in_so')) {
      return false;
    }
    return true;
  }

  return (
    <LoaderWrapper isFetching={isEventInfoFetching || isFetching}>
      <ActualContentContainer>
        <Form
          onSubmit={val => onSubmit(val, isEventGroupAdmin)}
          initialValues={formatInitialValues(initialValues)}
          mutators={{
            setTaxInfoDropdown: (args, state, utils) => {
              utils.changeValue(state, 'tax_info', () => {
                return getBillingCustomerId(
                  billingCustomerList,
                  eventTaxInfo && eventTaxInfo.billing_customer_id
                );
              });
            },
            setBillingDropdown: (args, state, utils) => {
              utils.changeValue(state, 'billing', () => {
                return getBillingCustomerId(
                  billingCustomerList,
                  eventTaxInfo && eventBilling.billing_customer_id
                );
              });
            }
          }}
          render={({ handleSubmit, form, pristine, invalid, submitting, reset, values }) => {
            billingMethodId = values.info.required_payment_profile;
            return (
              <form onSubmit={handleSubmit}>
                <Paper>
                  <FormContainer>
                    <InformationSection
                      sportOptions={sportsList.map(({ name, id }) => ({
                        name,
                        value: parseInt(id, 10)
                      }))}
                      groupOptions={groupsList.map(({ id, name }) => ({
                        name: `${name}  - ${id}`,
                        value: +id
                      }))}
                      governingBodies={governingBodies.map(({ value, label }) => ({
                        name: `${label}`,
                        value: `${value}`
                      }))}
                      isEventGroupAdmin={isEventGroupAdmin}
                      do_games_exist={do_games_exist}
                    />
                    <GameSettingsCollapsible do_games_exist={do_games_exist} />
                    <BillingSection
                      mutators={form.mutators}
                      isGamesEventGroupAdmin={isGamesEventGroupAdmin}
                      formValues={values}
                      hasInvoice={hasInvoice}
                      initialValue={eventBilling && eventBilling.billing_customer_id}
                      isFetchingBillingCustomerList={isFetchingBillingCustomerList}
                    />

                    {hasPaymentFeatures() && (
                      <TaxesSection
                        mutators={form.mutators}
                        billingMethodId={billingMethodId}
                        isGamesEventGroupAdmin={isGamesEventGroupAdmin}
                        formValues={values}
                        hasStagingBatch={hasStagingBatch}
                        initialValue={eventTaxInfo && eventTaxInfo.billing_customer_id}
                        isFetchingBillingCustomerList={isFetchingBillingCustomerList}
                      />
                    )}
                    <ButtonContainer>
                      <TwoColumnContainer
                        fields={[
                          <Button
                            label="Update"
                            primary
                            type="submit"
                            disabled={pristine || invalid || submitting}
                            name="update"
                          />,
                          <Button type="button" label="Cancel" onClick={reset} name="label" />
                        ]}
                        columns={2}
                      />
                    </ButtonContainer>
                  </FormContainer>
                </Paper>
              </form>
            );
          }}
        />
      </ActualContentContainer>
    </LoaderWrapper>
  );
};

Information.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  eventInfo: PropTypes.objectOf(Object),
  eventBilling: PropTypes.objectOf(Object),
  eventTaxInfo: PropTypes.objectOf(Object),
  sportsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ),
  groupsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ),
  governingBodies: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  )
};

Information.defaultProps = {
  eventInfo: {},
  eventBilling: {},
  eventTaxInfo: {},
  sportsList: [],
  governingBodies: [],
  groupsList: []
};

export default Information;
