// Actions

import { SELF_ASSIGNMENT_SUMMARY_ACTIONS } from '../../constants';
import { uniqueBy } from '../../utils/helpers';

export const FETCH_USER_AVAILABILITIES = 'USER/FETCH_USER_AVAILABILITIES';
export const SET_AVAILABILITIES = 'USER/SET_USER_AVAILABILITIES';

export const CREATE_USER_AVAILABILITY = 'USER/CREATE_USER_AVAILABILITY';
export const SET_AVAILABILITY = 'USER/SET_USER_AVAILABILITY';

export const UPDATE_USER_AVAILABILITY = 'USER/UPDATE_USER_AVAILABILITY';
export const UPDATE_AVAILABILITY = 'USER/UPDATE_AVAILABILITY';

export const DELETE_USER_AVAILABILITY = 'USER/DELETE_USER_AVAILABILITY';
export const DELETE_USER_ROLE = 'USER/DELETE_USER_ROLE';

export const FETCH_USER_ASSIGNMENTS = 'USER/FETCH_USER_ASSIGNMENTS';
export const FETCH_USER_SELF_ASSIGNMENT_SUMMARY = 'USER/FETCH_USER_SELF_ASSIGNMENT_SUMMARY';
export const SET_USER_SELF_ASSIGNMENT_SUMMARY = 'USER/SET_USER_SELF_ASSIGNMENT_SUMMARY';
export const CLEAR_SUMMARY = 'CLEAR_SUMMARY';
export const UPDATE_SUMMARY = 'UPDATE_SUMMARY';
export const SET_ASSIGNMENTS = 'USER/SET_ASSIGNMENTS';
export const CLEAR_ASSIGNMENTS = 'USER/CLEAR_ASSIGNMENTS';
export const SET_CURRENT_DAY_ASSIGNMENT_COUNT = 'USER/SET_CURRENT_DAY_ASSIGNMENT_COUNT';

export const STORE_PEOPLE_EVENT = 'STORE_PEOPLE_EVENT';
export const SET_PEOPLE_EVENT_ID = 'SET_PEOPLE_EVENT_ID';

export const SET_IS_FETCHING = 'USER/SET_IS_FETCHING';
export const SET_FETCHING = 'USER/SET_FETCHING';
export const SHOW_CALENDAR = 'USER/SHOW_CALENDAR';
export const SET_IS_FETCHING_TAX_REPORT = 'USER/SET_IS_FETCHING_TAX_REPORT';

export const FETCH_USER_EVENT_SETTINGS = 'FETCH_USER_EVENT_SETTINGS';
export const SET_USER_EVENT_SETTINGS = 'SET_USER_EVENT_SETTINGS';

export const FETCH_ACTIVE_USER_INFO = 'FETCH_ACTIVE_USER_INFO';
export const SET_ACTIVE_USER_INFO = 'SET_ACTIVE_USER_INFO';

export const FETCH_USER_TAX_INFO = 'FETCH_USER_TAX_INFO';
export const SET_USER_TAX_INFO = 'SET_USER_TAX_INFO';
export const CREATE_USER_TAX_INFO = 'CREATE_USER_TAX_INFO';
export const UPDATE_USER_TAX_INFO = 'UPDATE_USER_TAX_INFO';

export const FETCH_USER_PROFILE_META = 'FETCH_USER_PROFILE_META';
export const SET_USER_PROFILE_META = 'SET_USER_PROFILE_META';

export const FETCH_USER_SCHEDULE = 'FETCH_USER_SCHEDULE';
export const SET_USER_SCHEDULE = 'SET_USER_SCHEDULE';

export const FETCH_USER_SELF_ASSIGN_GAMES = 'FETCH_USER_SELF_ASSIGN_GAMES';
export const SET_USER_SELF_ASSIGN_GAMES = 'SET_USER_SELF_ASSIGN_GAMES';
export const CLEAR_USER_SELF_ASSIGNED_GAMES = 'CLEAR_USER_SELF_ASSIGNED_GAMES';
export const ADD_ASSIGNMENT_TO_SELF_ASSIGN_GAMES = 'ADD_ASSIGNMENT_TO_SELF_ASSIGN_GAMES';
export const ADD_ASSIGNMENT_TO_USER_SCHEDULE = 'ADD_ASSIGNMENT_TO_USER_SCHEDULE';
export const REMOVE_USER_SELF_ASSIGN_GAMES = 'REMOVE_USER_SELF_ASSIGN_GAMES';

export const FETCH_USER_AVAILABILITY_NOTE = 'FETCH_USER_AVAILABILITY_NOTE';
export const SET_USER_AVAILABILITY_NOTE = 'SET_USER_AVAILABILITY_NOTE';

export const FETCH_UPCOMING_GAMES = 'FETCH_UPCOMING_GAMES';
export const SET_UPCOMING_GAMES = 'SET_UPCOMING_GAMES';

export const FETCH_UPCOMING_ASSIGNMENTS = 'FETCH_UPCOMING_ASSIGNMENTS';
export const SET_UPCOMING_ASSIGNMENTS = 'SET_UPCOMING_ASSIGNMENTS';

export const FETCH_TAX_DATA = 'FETCH_TAX_DATA';
export const SET_TAX_DATA = 'SET_TAX_DATA';
export const CREATE_TAX_DATA = 'CREATE_TAX_DATA';
export const UPDATE_TAX_DATA = 'UPDATE_TAX_DATA';
export const CLEAR_TAX_DATA = 'CLEAR_TAX_DATA';
export const FETCH_TAX_REPORT_PDF = 'FETCH_TAX_REPORT_PDF';
// Reducers
export const ASSIGNMENTS = 'assignments';
export const PROFILE_META = 'profileMeta';
export const USERSCHEDULE = 'userSchedule';
export const SELFASSIGNGAMES = 'selfAssignGame';
export const AVAILABILITYNOTE = 'availabilityNote';
export const UPCOMINGGAMES = 'upcomingGames';
export const UPCOMINGASSIGNMENTS = 'upcomingAssignments';

export const initialState = {
  availabilities: [],
  assignments: [],
  isFetching: false,
  isFetchingPayee: false,
  isFetchingTaxReport: false,
  fetching: {
    assignments: false,
    profileMeta: true,
    userSchedule: false,
    selfAssignGame: false,
    availabilityNote: false,
    upcomingGames: false,
    upcomingAssignments: false
  },
  calendarIcon: {
    show: false,
    calendarActive: false
  },
  activeUser: {},
  taxInfo: {},
  tax_data: {},
  profileMeta: {
    payee: null,
    payment_profile: {
      required: false
    },
    profile: {
      completed: false
    },
    tax_info: null
  },
  userSchedule: {},
  selfAssignGames: {
    events: [],
    games: []
  },
  availabilityNote: {
    traget: '',
    note: ''
  },
  upcomingGames: [],
  upcomingAssignments: [],
  selfAssignmentSummary: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_AVAILABILITIES:
      return {
        ...state,
        availabilities: action.payload
      };
    case SET_AVAILABILITY:
      return {
        ...state,
        availabilities: [...state.availabilities, action.payload]
      };
    case UPDATE_AVAILABILITY: {
      const availabilityIdx = state.availabilities.findIndex(
        availability => action.payload.id === availability.id
      );
      return {
        ...state,
        availabilities: [
          ...state.availabilities.slice(0, availabilityIdx),
          {
            ...state.availabilities[availabilityIdx],
            ...action.payload
          },
          ...state.availabilities.slice(availabilityIdx + 1)
        ]
      };
    }
    case FETCH_USER_ASSIGNMENTS:
      return {
        ...state,
        assignments: []
      };
    case SET_ASSIGNMENTS:
      return {
        ...state,
        assignments: action.payload
      };
    case SET_USER_SELF_ASSIGNMENT_SUMMARY: {
      return {
        ...state,
        selfAssignmentSummary: action.payload && action.payload.summaryData,
        selfAssignGames: {
          events: [...action.payload.events],
          games: [...action.payload.games]
        }
      };
    }
    case CLEAR_SUMMARY:
      return {
        ...state,
        selfAssignmentSummary: []
      };
    case UPDATE_SUMMARY: {
      const { summary_action, day } = action.payload;
      let newSummary;
      if (
        summary_action === SELF_ASSIGNMENT_SUMMARY_ACTIONS.ADD &&
        !state.selfAssignmentSummary.includes(day)
      ) {
        newSummary = [...state.selfAssignmentSummary, day];
      } else if (summary_action === SELF_ASSIGNMENT_SUMMARY_ACTIONS.REMOVE) {
        newSummary = state.selfAssignmentSummary.filter(summaryDay => summaryDay !== day);
      } else {
        newSummary = [...state.selfAssignmentSummary];
      }

      return {
        ...state,
        selfAssignmentSummary: [...newSummary]
      };
    }
    case CLEAR_ASSIGNMENTS:
      return {
        ...state,
        assignments: []
      };
    case SET_CURRENT_DAY_ASSIGNMENT_COUNT:
      return {
        ...state,
        currentDayAssignmentCount: action.payload
      };
    case SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload
      };
    case SET_IS_FETCHING_TAX_REPORT:
      return {
        ...state,
        isFetchingTaxReport: action.payload
      };
    case SET_FETCHING:
      return {
        ...state,
        fetching: { ...state.fetching, [action.entity]: action.value }
      };
    case SET_PEOPLE_EVENT_ID:
      return {
        ...state,
        peopleEventId: action.payload
      };
    case STORE_PEOPLE_EVENT:
      return {
        ...state,
        peopleEvent: action.payload
      };
    case SHOW_CALENDAR:
      return {
        ...state,
        calendarIcon: { ...state.calendarIcon, ...action.payload }
      };
    case SET_USER_EVENT_SETTINGS:
      return {
        ...state,
        showBanner: action.payload
      };
    case SET_ACTIVE_USER_INFO:
      return {
        ...state,
        activeUser: { ...state.activeUser, ...action.payload }
      };
    case SET_USER_TAX_INFO:
      return {
        ...state,
        taxInfo: action.payload
      };
    case SET_USER_PROFILE_META:
      return {
        ...state,
        profileMeta: action.payload
      };
    case SET_USER_SCHEDULE:
      return {
        ...state,
        userSchedule: action.payload
      };
    case SET_USER_SELF_ASSIGN_GAMES: {
      const payloadGames = (action && action.payload && action.payload.games) || [];
      const stateGames = (state && state.selfAssignGames && state.selfAssignGames.games) || [];
      let newGames = payloadGames;
      if (stateGames.length > 0) {
        newGames = payloadGames.filter(
          game => !stateGames.find(stateGame => stateGame.id === game.id)
        );
      }
      const games = uniqueBy([...stateGames, ...newGames], game => game.id);
      return {
        ...state,
        selfAssignGames: {
          events: [...state.selfAssignGames.events, ...action.payload.events],
          games
        }
      };
    }
    case CLEAR_USER_SELF_ASSIGNED_GAMES:
      return {
        ...state,
        selfAssignGames: {
          ...state.selfAssignGames,
          games: []
        }
      };
    case ADD_ASSIGNMENT_TO_SELF_ASSIGN_GAMES: {
      const gameIdx = state.selfAssignGames.games.findIndex(
        game => game.id === action.payload.assignment.external_game_id
      );
      return {
        ...state,
        selfAssignGames: {
          ...state.selfAssignGames,
          games: [
            ...state.selfAssignGames.games.slice(0, gameIdx),
            {
              ...state.selfAssignGames.games[gameIdx],
              assignments_game: [
                ...state.selfAssignGames.games[gameIdx].assignments_game,
                action.payload.assignment
              ]
            },
            ...state.selfAssignGames.games.slice(gameIdx + 1)
          ]
        }
      };
    }
    case REMOVE_USER_SELF_ASSIGN_GAMES: {
      const day = action.payload.games_day_to_remove;
      const newGames = state.selfAssignGames.games.filter(stateGame => {
        const gameDate = new Date(stateGame.start_at).getDate();
        return day !== gameDate;
      });
      return {
        ...state,
        selfAssignGames: {
          events: [...state.selfAssignGames.events],
          games: [...newGames]
        }
      };
    }
    case ADD_ASSIGNMENT_TO_USER_SCHEDULE: {
      const eventRoleIdx = state.userSchedule.event_roles.findIndex(
        role => role.id === action.payload.eventRoleId && role.event_id === action.payload.eventId
      );
      return {
        ...state,
        userSchedule: {
          ...state.userSchedule,
          event_roles: [
            ...state.userSchedule.event_roles.slice(0, eventRoleIdx),
            {
              ...state.userSchedule.event_roles[eventRoleIdx],
              game_assignments: [
                ...state.userSchedule.event_roles[eventRoleIdx].game_assignments,
                action.payload.assignment
              ]
            },
            ...state.userSchedule.event_roles.slice(eventRoleIdx + 1)
          ]
        }
      };
    }
    case SET_USER_AVAILABILITY_NOTE: {
      return {
        ...state,
        availabilityNote: {
          note: action.payload && action.payload.note ? action.payload.note : '',
          target: action.payload && action.payload.target ? action.payload.target : ''
        }
      };
    }
    case SET_UPCOMING_GAMES: {
      return {
        ...state,
        upcomingGames: action.payload
      };
    }
    case SET_UPCOMING_ASSIGNMENTS: {
      return {
        ...state,
        upcomingAssignments: action.payload
      };
    }
    case SET_TAX_DATA: {
      return {
        ...state,
        tax_data: action.payload
      };
    }
    default:
      return state;
  }
}

// Action Creators
export const fetchUserAvailabilities = payload => ({
  type: FETCH_USER_AVAILABILITIES,
  payload
});

export const setAvailabilities = payload => ({
  type: SET_AVAILABILITIES,
  payload
});

export const createUserAvailability = payload => ({
  type: CREATE_USER_AVAILABILITY,
  payload
});

export const setAvailability = payload => ({
  type: SET_AVAILABILITY,
  payload
});

export const updateUserAvailability = payload => ({
  type: UPDATE_USER_AVAILABILITY,
  payload
});

export const deleteUserAvailability = payload => ({
  type: DELETE_USER_AVAILABILITY,
  payload
});

export const updateAvailability = payload => ({
  type: UPDATE_AVAILABILITY,
  payload
});

export const fetchUserAssignments = payload => ({
  type: FETCH_USER_ASSIGNMENTS,
  payload
});

export const setAssignments = payload => ({
  type: SET_ASSIGNMENTS,
  payload
});

export const fetchUserSelfAssignmentSummaryAction = payload => ({
  type: FETCH_USER_SELF_ASSIGNMENT_SUMMARY,
  payload
});

export const setUserSelfAssignmentSummaryAction = payload => ({
  type: SET_USER_SELF_ASSIGNMENT_SUMMARY,
  payload
});

export const clearSummary = () => ({
  type: CLEAR_SUMMARY
});

export const updateSummary = payload => ({
  type: UPDATE_SUMMARY,
  payload
});

export const clearAssignments = payload => ({
  type: CLEAR_ASSIGNMENTS,
  payload
});

export const setCurrentDayAssignmentCount = payload => ({
  type: SET_CURRENT_DAY_ASSIGNMENT_COUNT,
  payload
});

export const setIsFetching = payload => ({
  type: SET_IS_FETCHING,
  payload
});

export const setFetching = (entity, value) => ({
  type: SET_FETCHING,
  entity,
  value
});

export const setFetchingTaxReport = payload => ({
  type: SET_IS_FETCHING_TAX_REPORT,
  payload
});

export const storePeopleEvent = payload => ({
  type: STORE_PEOPLE_EVENT,
  payload
});

export const setPeopleEventId = payload => ({
  type: SET_PEOPLE_EVENT_ID,
  payload
});

export const setShowCalendar = payload => ({
  payload,
  type: SHOW_CALENDAR
});

export const fetchUserEventSettings = payload => ({
  type: FETCH_USER_EVENT_SETTINGS,
  payload
});

export const setUserEventSettings = payload => ({
  type: SET_USER_EVENT_SETTINGS,
  payload
});

export const fetchActiveUserInfo = payload => ({
  type: FETCH_ACTIVE_USER_INFO,
  payload
});

export const setActiveUserInfo = payload => ({
  type: SET_ACTIVE_USER_INFO,
  payload
});

export const fetchUserTaxInfo = payload => ({
  type: FETCH_USER_TAX_INFO,
  payload
});

export const setUserTaxInfo = payload => ({
  type: SET_USER_TAX_INFO,
  payload
});

export const createUserTaxInfo = payload => ({
  type: CREATE_USER_TAX_INFO,
  payload
});

export const updateUserTaxInfo = payload => ({
  type: UPDATE_USER_TAX_INFO,
  payload
});

export const deleteUserRole = payload => ({
  type: DELETE_USER_ROLE,
  payload
});

export const fetchUserProfileMeta = payload => ({
  type: FETCH_USER_PROFILE_META,
  payload
});

export const setUserProfileMeta = payload => ({
  type: SET_USER_PROFILE_META,
  payload
});

export const fetchUserSchedule = payload => ({
  type: FETCH_USER_SCHEDULE,
  payload
});

export const setUserSchedule = payload => ({
  type: SET_USER_SCHEDULE,
  payload
});

export const fetchUserSelfAssignGames = payload => ({
  type: FETCH_USER_SELF_ASSIGN_GAMES,
  payload
});

export const setUserSelfAssignGames = payload => ({
  type: SET_USER_SELF_ASSIGN_GAMES,
  payload
});

export const clearUserSelfAssignedGamesAction = () => ({
  type: CLEAR_USER_SELF_ASSIGNED_GAMES
});

export const addAssignmentToSelfAssignGames = payload => ({
  type: ADD_ASSIGNMENT_TO_SELF_ASSIGN_GAMES,
  payload
});

export const removeUserSelfAssignGames = payload => ({
  type: REMOVE_USER_SELF_ASSIGN_GAMES,
  payload
});

export const addAssignmentToUseSchedule = payload => ({
  type: ADD_ASSIGNMENT_TO_USER_SCHEDULE,
  payload
});

export const fetchUserAvailabilityNote = payload => ({
  type: FETCH_USER_AVAILABILITY_NOTE,
  payload
});

export const setUserAvailabilityNote = payload => ({
  type: SET_USER_AVAILABILITY_NOTE,
  payload
});

export const fetchUpcomingGames = payload => ({
  type: FETCH_UPCOMING_GAMES,
  payload
});

export const fetchUpcomingAssignments = payload => ({
  type: FETCH_UPCOMING_ASSIGNMENTS,
  payload
});

export const setUpcomingGames = payload => ({
  type: SET_UPCOMING_GAMES,
  payload
});

export const setUpcomingAssignments = payload => ({
  type: SET_UPCOMING_ASSIGNMENTS,
  payload
});

export const fetchTaxdataSaga = payload => ({
  type: FETCH_TAX_DATA,
  payload
});

export const setTaxdataSaga = payload => ({
  type: SET_TAX_DATA,
  payload
});

export const createTaxdataSaga = payload => ({
  type: CREATE_TAX_DATA,
  payload
});

export const updateTaxdataSaga = payload => ({
  type: UPDATE_TAX_DATA,
  payload
});

export const clearTaxdataSaga = payload => ({
  type: CLEAR_TAX_DATA,
  payload
});

export const fetchTaxReportPdfSaga = payload => ({
  type: FETCH_TAX_REPORT_PDF,
  payload
});
