import React from 'react';
import PropTypes from 'prop-types';
import { IconWithTextWrapper, IconText, StyledIcon } from './styled-components';

const IconWithText = ({ icon, text, width, height, color, textColor, onClick }) => (
  <IconWithTextWrapper onClick={onClick}>
    <StyledIcon icon={icon} width={width} height={height} color={color} />
    <IconText color={textColor}>{text}</IconText>
  </IconWithTextWrapper>
);

IconWithText.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  textColor: PropTypes.string
};

IconWithText.defaultProps = {
  width: 16,
  height: 16,
  color: '',
  textColor: 'black'
};

export default IconWithText;
