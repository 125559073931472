import styled from 'styled-components';

import { Link } from 'react-router-dom';
import { RawButton } from '../Button/styled-components';
import { pxToRem } from '../../../utils/helpers';
import { COLORS, mediaQueries } from '../../../utils/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${pxToRem('30px')};
  background-color: ${COLORS.white};
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  height: ${({ isOnBoardingUrl }) => (isOnBoardingUrl ? '800px' : 'auto')};
  ${mediaQueries.md`
  padding:15px;
  height:auto;
  `};
`;

export const Header = styled.div`
  padding: ${pxToRem('15px')};
  background-color: ${COLORS.blackSqueeze};
`;

export const Title = styled.h3`
  margin: 0;
  font: 500 ${pxToRem('15px')} 'RobotoMedium', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.5px;
`;

export const Content = styled.div`
  flex: 1;
`;

export const StyledLink = styled(Link)`
  & .nextBtn {
    background: ${COLORS.denimBlue} !important;
    color: ${COLORS.white} !important;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: ${pxToRem('50px')};

  ${RawButton} {
    width: ${pxToRem('150px')};
  }

  & > ${RawButton}:not(:last-child) {
    margin-right: ${pxToRem('16px')};
  }
`;

export const ActualContentContainer = styled.div`
  align-self: center;
  width: 100%;
  height: 100%;
  padding: ${isOnBoardingUrl => (isOnBoardingUrl ? '0px' : '2rem')};
  box-sizing: border-box;
  ${mediaQueries.md`
  padding:0px;
  `}
`;

export default Container;
