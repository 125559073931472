import styled from 'styled-components';
import Icon from '../../../Icon';

export const IconWithTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap; /* Allow wrapping if needed */
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #f2f2f2;
  }
`;

export const StyledIcon = styled(Icon)`
  margin: 0;
  padding-bottom: 2px;
`;

export const IconText = styled.span`
  margin-top: 10px;
  font-size: 12px;
  color: ${props => props.color || 'black'};
  text-align: center;
  font-weight: bold;
  max-width: 90%;
`;
