import React from 'react';
import Enhancer from '../../containers/SignIn';
import { getLoginURL } from '../../../utils/helpers';
import logoConnect from '../../../assets/images/new-official-connect-logo-white-red.png';
import {
  LoginLink,
  ButtonWrapper,
  LoginButton,
  Logo,
  Header,
  Iframe,
  Wrapper
} from './styled-components';

const SignIn = () => (
  <Wrapper>
    <Header>
      <Logo src={logoConnect} alt="logoConnect" />
      <ButtonWrapper>
        <LoginLink href={getLoginURL()}>
          <LoginButton width="120px" height="35px" fontSize="14px">
            Log In
          </LoginButton>
        </LoginLink>
      </ButtonWrapper>
    </Header>
    <Iframe src="https://sportsconnect.com/officials-connect/" title="OfficialsConnect" />
  </Wrapper>
);

export default Enhancer(SignIn);
