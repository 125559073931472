import React from 'react';
import { Row } from 'react-flexbox-grid';
import TitleBar from '../../TitleBar';
import COLORS, { ICONS } from '../../../../utils/styles';
import { SubSectionWrapper } from '../../../../global-styles/global-styled-components';
import IconWithText from './IconWithText';
import { NoteWrapper } from './styled-components';

export default function TaxForms({ taxReports, fetchPdf, isFetchingTaxReport }) {
  const style = { width: '100%', font: 'Roboto' };

  const handleClick = (value, name, event) => {
    event.preventDefault();
    if (!isFetchingTaxReport) {
      fetchPdf({ id: value, file_name: name });
    }
  };

  return (
    <form style={style}>
      {taxReports && taxReports.length > 0 && (
        <>
          <TitleBar margin="20px 0px 0px 0px" bgColor={COLORS.blueGray}>
            TAX FORMS BY YEAR
          </TitleBar>
          <Row between="xs">
            <SubSectionWrapper xs={14} md={14}>
              <Row style={{ marginLeft: '10px', marginRight: '10px' }}>
                <NoteWrapper>
                  {taxReports.map(pdf => (
                    <IconWithText
                      icon={ICONS.PDF}
                      text={pdf.name}
                      width={55}
                      height={50}
                      color={COLORS.darkBlue}
                      textColor={COLORS.darkBlue}
                      onClick={e => handleClick(pdf.id, pdf.name, e)}
                    />
                  ))}
                </NoteWrapper>
              </Row>
            </SubSectionWrapper>
          </Row>
        </>
      )}
    </form>
  );
}
