import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import unconnected from './unconnected';
import {
  clearTaxdataSaga,
  createTaxdataSaga,
  fetchActiveUserInfo,
  fetchTaxdataSaga,
  fetchTaxReportPdfSaga,
  updateTaxdataSaga
} from '../../../core/User';
import { getRsaPublicKey, isFundingAdmin, isSuperAdmin } from '../../../core/Auth/selectors';
import { getParamId } from '../../../utils/helpers';
import { userIdxGetter } from '../../../utils/auth';
import { addPayeeAccount, editPayeeAccount } from '../../../core/Auth';
import { getTaxData } from '../../../core/User/selectors';

const mapStateToProps = (state, props) => ({
  paramId: getParamId(props),
  user: state.auth && state.user.activeUser,
  isFetching: state.user.isFetching,
  userIdx: userIdxGetter(state),
  path: props && props.match && props.match.url,
  pathUrl: props && props.match && props.match.path,
  isFundingAdmin: isFundingAdmin(state),
  isSuperAdmin: isSuperAdmin(state),
  bankAccounts: state.auth && state.auth.activeUser && state.auth.activeUser.bankAccounts,
  currentUserId: state.auth && state.auth.user && state.auth.user.id,
  isPaidThroughSO: state.user.activeUser && state.user.activeUser.is_paid_through_so,
  rsaPublic: getRsaPublicKey(state),
  taxData: getTaxData(state),
  isFetchingTaxReport: state.user.isFetchingTaxReport
});

const mapDispatchToProps = dispatch => ({
  addPayeeAccountAction: info => dispatch(addPayeeAccount(info)),
  editPayeeAccountAction: info => dispatch(editPayeeAccount(info)),
  fetchActiveUserInfo: payload => dispatch(fetchActiveUserInfo(payload)),
  createTaxDataDispatch: payload => dispatch(createTaxdataSaga(payload)),
  updateTaxDataDispatch: payload => dispatch(updateTaxdataSaga(payload)),
  clearTaxDataDispatch: payload => dispatch(clearTaxdataSaga(payload)),
  getTaxDataDispatch: payload => dispatch(fetchTaxdataSaga(payload)),
  fetchPdf: payload => dispatch(fetchTaxReportPdfSaga(payload))
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps), unconnected);
