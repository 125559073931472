/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { withStyles } from '@material-ui/styles';
import CollapsableContent from '../../../CollapsableContent';
import { PrimaryButton } from '../../../../global-material-components';
import ConfirmationPopup from '../../../popupModals/ComfirmationPopup';
import MouseOverPopover from '../../../../global-material-components/Popover';
import {
  CenteredContentContainer,
  FlexRow,
  AccountText,
  PayoutText,
  ContainerPadding,
  InnerFormCol,
  RowWrapper,
  ContainerPaddingTopBottom
} from './styled-components';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    MenuListProps={{ style: { padding: 0, width: '80px' } }}
    {...props}
  />
));

export default function DirectDeposit({
  account: accounts,
  addPayeeAccountAction,
  userId,
  editPayeeAccountAction,
  addBankAccount,
  currentUserId
}) {
  const [newAccountShowing] = React.useState(false);
  const [editingAccount] = React.useState(false);
  const [confirmationPopupAction, setConfirmationPopupAction] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }
  const accountExists = accounts && !!accounts.length;
  const isLoggedInUser = currentUserId === userId;
  return (
    <ContainerPadding>
      {confirmationPopupAction && (
        <ConfirmationPopup
          title="Confirmation"
          description={
            <p>
              Stack Officials uses STRIPE to process online payments.{' '}
              <b style={{ fontWeight: 'bold' }}>Note:</b> Officials under the age of 18 must provide
              Parent or Guardian information (such as name, date of birth, and taxpayer id) to
              complete this process. Click <b style={{ fontWeight: 'bold' }}>Continue</b> to set up
              your payment profile on Stripe.
            </p>
          }
          SubmitBtnText="Continue"
          confirmationPopupAction={confirmationPopupAction}
          setConfirmationPopupAction={setConfirmationPopupAction}
          primaryActionFun={addPayeeAccountAction}
        />
      )}
      <RowWrapper center="xs">
        <InnerFormCol xs={12}>
          <CollapsableContent title="PAYOUT OPTIONS" pl={0} isOpen={1} setOpen externalIsOpen>
            {newAccountShowing || editingAccount ? (
              <ContainerPadding>
                <AccountText color="#484848" marginL="0.8rem" marginT="0.8rem" display="block">
                  To enable payouts on your account, please update and complete the payment profile
                  below. <b>For users under the age of 18</b>, the parent or guardian&apos;s
                  information will be used to complete this process:t profile below:
                </AccountText>
                <PrimaryButton
                  textTransform="uppercase"
                  onClick={setConfirmationPopupAction(true)}
                  disabled={!isLoggedInUser}
                >
                  Setup Payment Profile
                </PrimaryButton>
              </ContainerPadding>
            ) : (
              <CenteredContentContainer>
                {accountExists ? (
                  accounts.map(account => (
                    <FlexRow start="xs">
                      {account &&
                      account.attributes &&
                      account.attributes.external_accounts &&
                      account.attributes.external_accounts.length ? (
                        <>
                          <PayoutText> Account Info:</PayoutText>
                        </>
                      ) : (
                        ''
                      )}
                      {account &&
                      account.attributes &&
                      account.attributes.external_accounts &&
                      account.attributes.external_accounts.length ? (
                        account.attributes.external_accounts.map(
                          ({ last4, brand, exp_year, exp_month }) => (
                            <>
                              <AccountText>
                                {' '}
                                {brand} XXXX-XXXX-XXXX-{last4}{' '}
                              </AccountText>
                              <>
                                <AccountText style={{ marginLeft: '3rem' }}>
                                  {account.attributes.payouts_enabled !== undefined ? (
                                    <>
                                      {!account.attributes.payouts_enabled && (
                                        <MouseOverPopover
                                          transformOriginHorizontal="right"
                                          messageTitle="Enable Profile Info"
                                          messageBody="Payouts are enabled when the payment processor has verified the account"
                                        >
                                          <PayoutText>Payout Enabled: </PayoutText>{' '}
                                          <AccountText>
                                            {' '}
                                            {account.attributes.payouts_enabled ? 'Yes' : 'No'}
                                          </AccountText>
                                        </MouseOverPopover>
                                      )}
                                      {account.attributes.payouts_enabled && (
                                        <PayoutText>
                                          Payout Enabled: <AccountText>Yes</AccountText>
                                        </PayoutText>
                                      )}
                                    </>
                                  ) : (
                                    ''
                                  )}{' '}
                                </AccountText>
                              </>
                              <>
                                <AccountText style={{ float: 'right', marginLeft: '3rem' }}>
                                  {}
                                  {exp_year ? (
                                    <>
                                      <PayoutText>Exp: </PayoutText> {exp_month}/{exp_year}
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </AccountText>
                              </>
                            </>
                          )
                        )
                      ) : (
                        <RowWrapper start="xs">
                          <ContainerPaddingTopBottom xs={12}>
                            <AccountText color="#484848" display="block">
                              To enable payouts on your account, please update and complete the
                              payment profile below. <b>For users under the age of 18</b>, the
                              parent or guardian&apos;s information will be used to complete this
                              process:
                            </AccountText>
                          </ContainerPaddingTopBottom>
                          <ContainerPaddingTopBottom xs={12}>
                            <PrimaryButton
                              textTransform="uppercase"
                              onClick={() => {
                                if (account.id) {
                                  editPayeeAccountAction({
                                    id: account.id,
                                    userId
                                  });
                                } else {
                                  addBankAccount({ userId });
                                }
                              }}
                              disabled={!isLoggedInUser}
                            >
                              {' '}
                              Update Payment Profile{' '}
                            </PrimaryButton>
                          </ContainerPaddingTopBottom>
                        </RowWrapper>
                      )}

                      {account &&
                      account.attributes &&
                      account.attributes.external_accounts &&
                      account.attributes.external_accounts.length ? (
                        <>
                          <IconButton onClick={handleClick}>
                            <MoreVertIcon />
                          </IconButton>
                          {isLoggedInUser && (
                            <StyledMenu
                              id="simple-menu"
                              anchorEl={anchorEl}
                              keepMounted
                              open={Boolean(anchorEl)}
                              onClose={handleClose}
                            >
                              <MenuItem
                                onClick={() => {
                                  handleClose();
                                  if (account.id) {
                                    editPayeeAccountAction({
                                      id: account.id,
                                      userId
                                    });
                                  } else {
                                    addBankAccount({ userId });
                                  }
                                }}
                              >
                                Edit
                              </MenuItem>
                            </StyledMenu>
                          )}
                        </>
                      ) : (
                        ''
                      )}
                    </FlexRow>
                  ))
                ) : (
                  <ContainerPadding>
                    <RowWrapper start="xs">
                      <ContainerPaddingTopBottom xs={12}>
                        {' '}
                        <AccountText color="#484848" display="block">
                          To enable payouts on your account, please update and complete the payment
                          profile below. <b>For users under the age of 18</b>, the parent or
                          guardian&apos;s information will be used to complete this process:
                        </AccountText>
                      </ContainerPaddingTopBottom>
                      <ContainerPaddingTopBottom xs={12}>
                        {' '}
                        <PrimaryButton
                          textTransform="uppercase"
                          onClick={() => setConfirmationPopupAction(true)}
                          disabled={!isLoggedInUser}
                        >
                          Setup Payment Profile
                        </PrimaryButton>
                      </ContainerPaddingTopBottom>
                    </RowWrapper>
                  </ContainerPadding>
                )}
              </CenteredContentContainer>
            )}
          </CollapsableContent>
        </InnerFormCol>
      </RowWrapper>
    </ContainerPadding>
  );
}
