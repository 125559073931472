/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Box, Tab, Tabs, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LoaderWrapper from '../../../LoaderWrapper';
import {
  ModalContainer,
  HeaderRow,
  Batch,
  FundTotal,
  FundTotalText,
  FundStyle,
  InputStyle,
  TabsContainer,
  BatchDetails
} from '../styled-components';
import { numToCurrency } from '../../../../../utils/parsers';
import { Dialog, Input } from '../../../../global-material-components';

import Card from '../paymentMethods/card';
import ACHAccount from '../paymentMethods/ach-account';
import PreviewFundBatch from '../../PreviewFundBatch';
import COLORS from '../../../../../utils/styles';

import RoundedCheckBox from '../../../RoundedCheckBox';
import { ENTITIES, PAYMENT_METHODS } from '../../../../../constants';
import { sortListByKey } from '../../../../../utils/helpers';
import SOBalance from '../paymentMethods/sobalance';

const TABS = { BANK: 0, CARD: 1 };

const useStyles = makeStyles({
  fundingDialogRoot: {
    minWidth: '1000px',
    maxHeight: '710px'
  },
  paymentMethodContainer: {
    overflowY: 'auto'
    // maxHeight: '380px'
  }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box>{children}</Box>
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const FundBatchDialog = ({
  activeTab,
  setActiveTab,
  isFetching,
  addPaymentSource,
  processBatchFund,
  user,
  fundSummaryDetails,
  batchPaymentFee,
  addNotifications,
  setFundDialogOpen,
  currentPaymentSource,
  currentBatch,
  fundDialogOpen,
  paymentSources,
  handlePaymentSource,
  currentPaymentSourceMeta,
  setCurrentPaymentSourceMeta,
  setValues,
  values,
  isPaymentSupported,
  agreement,
  dialogDisplay,
  setDialogDisplay,
  userWallet
}) => {
  const classes = useStyles();
  const [batchFee, setBatchFee] = React.useState(null);
  const [agreementPayload, setAgreementPayload] = React.useState(null);
  const [newAccount, toggleNewAccount] = React.useState(null);
  const [addNew, isAddNew] = React.useState(false);

  const sortedFundSummaryDetails =
    fundSummaryDetails &&
    fundSummaryDetails.length &&
    sortListByKey(fundSummaryDetails, 'last_name');
  const customStyle = { minHeight: 'auto', overflowY: 'auto' };

  const initialBatchFee =
    fundSummaryDetails && fundSummaryDetails.length ? 10 + 1.5 * fundSummaryDetails.length : 0;
  React.useEffect(() => {
    if (batchPaymentFee !== null) {
      if (currentBatch && batchPaymentFee) {
        if (
          currentBatch.pay_batched + batchPaymentFee.totalFees < batchPaymentFee.min_amount ||
          currentBatch.pay_batched + batchPaymentFee.totalFees > batchPaymentFee.max_amount
        ) {
          if (currentBatch.pay_batched + batchPaymentFee.totalFees < batchPaymentFee.min_amount) {
            setValues({
              ...values,
              batchTotalError: `The least amount required for the funding the batch is $${batchPaymentFee.min_amount}.`
            });
          }

          if (currentBatch.pay_batched + batchPaymentFee.totalFees > batchPaymentFee.max_amount) {
            setValues({
              ...values,
              batchTotalError: `The amount for funding the batch should not exceed $${batchPaymentFee.max_amount}.`
            });
          }
        } else {
          setValues({
            ...values,
            batchTotalError: ''
          });
        }
      }
      setBatchFee(batchPaymentFee.totalFees);
    } else setBatchFee(initialBatchFee);
    // eslint-disable-next-line
  }, [batchPaymentFee, fundSummaryDetails]);

  React.useEffect(() => {
    if (!addNew)
      toggleNewAccount(
        activeTab === TABS.BANK &&
          (!paymentSources.bank_account || !paymentSources.bank_account.length)
      );
    // eslint-disable-next-line
  }, [paymentSources.bank_account, activeTab]);

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  function handleChangeTab(_, newValue) {
    handlePaymentSource(null);
    setActiveTab(newValue);
    setBatchFee(initialBatchFee);
    toggleNewAccount(
      newValue === TABS.BANK &&
        (!paymentSources.bank_account || !paymentSources.bank_account.length)
    );
  }

  function acceptAgreement(value) {
    if (!agreement.doc_agreement) return null;
    if (value) {
      setAgreementPayload({
        doc_id: agreement.id,
        agreement_id: agreement.doc_agreement.id,
        agreed_by_user: user.id,
        ref: {
          id: currentBatch.id,
          code: ENTITIES.BATCH_PAY
        }
      });
    } else {
      setAgreementPayload(null);
    }
    return true;
  }

  const hasSufficientFund =
    currentPaymentSource === PAYMENT_METHODS.WALLET &&
    currentBatch &&
    !(currentBatch.pay_batched + batchFee <= userWallet.available);

  const fundDisabled = () => {
    return (
      !values.description ||
      !currentPaymentSource ||
      values.batchTotalError !== '' ||
      !isPaymentSupported ||
      !agreementPayload ||
      hasSufficientFund
    );
  };

  return (
    <Dialog
      id="addPaymentMethodDialog"
      title="CHOOSE ACCOUNT"
      onClose={() => {
        setFundDialogOpen(false);
        setBatchFee(initialBatchFee);
      }}
      open={fundDialogOpen}
      cancelAction={() => {
        setCurrentPaymentSourceMeta({
          name: null,
          address: { line1: null, line2: null, city: null, state: null, country: 'US' }
        });
        setBatchFee(initialBatchFee);
        setFundDialogOpen(false);
      }}
      primaryAction={() => {
        processBatchFund({
          userId: user.id,
          batchId: currentBatch.id,
          paymentSource: currentPaymentSource,
          description: values.description,
          agreement: agreementPayload
        });
        setBatchFee(initialBatchFee);
      }}
      primaryButtonText="Fund Batch"
      primaryButtonStyle={FundStyle}
      primaryButtonDisabled={fundDisabled()}
      noCancel
      customStyle={customStyle}
      classes={{ paper: classes.fundingDialogRoot }}
      footerBackground={(newAccount && COLORS.greyout) || ''}
      style={{ display: dialogDisplay }}
      helpText={
        activeTab === 1
          ? 'There must be enough funds in your available balance to cover this batch payment.'
          : ''
      }
    >
      <ModalContainer newAccount={newAccount}>
        <LoaderWrapper isFetching={isFetching}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <BatchDetails>
                {currentBatch ? (
                  <HeaderRow>
                    <Batch>{`Batch ID: ${currentBatch.id}`}</Batch>
                    <FundTotal>{`${numToCurrency(currentBatch.pay_batched + batchFee)}`}</FundTotal>
                    <FundTotalText>Fund Total</FundTotalText>
                  </HeaderRow>
                ) : null}
                <Input
                  id="description"
                  placeholder="Description* (96 char. max)"
                  label="Description"
                  onChange={handleChange('description')}
                  style={InputStyle}
                  value={values.description}
                  maxLength="96"
                  errorMessage="Required"
                  error={!values.description}
                  relativeError
                  disabled={newAccount}
                />
              </BatchDetails>
              <div>
                <center>
                  <span style={{ color: COLORS.errorRed }}>{values.batchTotalError}</span>
                </center>
                <PreviewFundBatch
                  fundSummaryDetails={sortedFundSummaryDetails}
                  batchFee={batchFee}
                  contentBackground={(newAccount && COLORS.greyout) || ''}
                />
              </div>
            </Grid>
            <Grid item xs={6} style={{ backgroundColor: COLORS.white }}>
              <TabsContainer>
                <Tabs
                  value={activeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleChangeTab}
                  aria-label="simple tabs example"
                >
                  <Tab label="Bank Accounts" {...a11yProps(0)} />
                  <Tab label="Wallet" {...a11yProps(1)} />
                  <Tab label="Cards" {...a11yProps(2)} />
                </Tabs>
              </TabsContainer>
              <div style={{ margin: '20px 0px 10px 0px' }} />
              <>
                <TabPanel
                  value={activeTab}
                  index={0}
                  classes={{ root: classes.paymentMethodContainer }}
                >
                  <ACHAccount
                    accounts={paymentSources.bank_account}
                    setSelectBank={handlePaymentSource}
                    selectBank={currentPaymentSource}
                    addBank={addPaymentSource}
                    isFetching={isFetching}
                    addNotifications={addNotifications}
                    newAccount={newAccount}
                    toggleNewAccount={toggleNewAccount}
                    isAddNew={isAddNew}
                    setDialogDisplay={setDialogDisplay}
                  />
                </TabPanel>
                <TabPanel
                  value={activeTab}
                  index={1}
                  classes={{ root: classes.paymentMethodContainer }}
                >
                  <SOBalance
                    setWalletSelected={handlePaymentSource}
                    selectWallet={currentPaymentSource}
                    user={user}
                    userWallet={userWallet}
                  />
                </TabPanel>
                <TabPanel
                  value={activeTab}
                  index={2}
                  classes={{ root: classes.paymentMethodContainer }}
                >
                  <Card
                    cards={paymentSources.card}
                    billingInfo={currentPaymentSourceMeta}
                    setBillingInfo={setCurrentPaymentSourceMeta}
                    setCard={handlePaymentSource}
                    card={currentPaymentSource}
                    addCard={addPaymentSource}
                    addNotifications={addNotifications}
                  />
                </TabPanel>
              </>
            </Grid>
          </Grid>
          <div>
            <RoundedCheckBox
              mainContainerStyle={{ display: 'flex' }}
              onChange={acceptAgreement}
              value={agreementPayload}
              disabled={!agreement.doc_agreement || newAccount}
              label={agreement.doc_agreement && agreement.doc_agreement.agree_to_text}
              labelStyle={{ margin: '0.9rem 0.5rem', textAlign: 'justify' }}
              customStyle={{ margin: '0.9rem 0.5rem' }}
              locked={newAccount}
            />
          </div>
        </LoaderWrapper>
      </ModalContainer>
    </Dialog>
  );
};

export default FundBatchDialog;
