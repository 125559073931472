import { compose, lifecycle, pure } from 'recompose';
import { connect } from 'react-redux';
import TagManager from 'react-gtm-module';

import { getProgressVisibility } from '../../../core/Progress';
import {
  isAnyEventAdmin,
  isAnyEventAssignor,
  isAnyEventOfficialOrAssessor,
  isAnyEventAssignorOrAssessorOrOfficial,
  isGroupAdmin,
  isSuperAdmin,
  isFundingAdmin,
  getUserProfile
} from '../../../core/Auth/selectors';
import {
  hasPaymentProfileRequired,
  hasProfileComplete,
  hasTaxInfo,
  payeeStatus
} from '../../../core/User/selectors';
import { fetchUserProfileMeta as fetchUserProfileMetaAction } from '../../../core/User';
import intercomScript from '../../../scripts/intercom';

export default compose(
  connect(
    state => ({
      isFetching: getProgressVisibility(state),
      isFetchingProfileMeta: state.user.fetching,
      assignor: isAnyEventAssignor()(state),
      admin: isAnyEventAdmin()(state),
      groupAdmin: isGroupAdmin(state),
      superAdmin: isSuperAdmin(state),
      officialAssessor: isAnyEventOfficialOrAssessor()(state),
      officialAssessorAssignor: isAnyEventAssignorOrAssessorOrOfficial(state),
      isProfileCompleted: hasProfileComplete(state),
      taxInfo: hasTaxInfo(state),
      isPaymentSetUpComplete: payeeStatus(state),
      isPaymentProfileRequired: hasPaymentProfileRequired(state),
      fundingAdmin: isFundingAdmin(state),
      isPaidThroughSO: state.user.profileMeta && state.user.profileMeta.is_paid_through_so,
      userProfile: getUserProfile(state)
    }),
    dispatch => ({
      fetchUserProfileMeta: payload => dispatch(fetchUserProfileMetaAction(payload))
    })
  ),
  lifecycle({
    componentDidMount() {
      const {
        fetchUserProfileMeta,
        groupAdmin,
        admin,
        userProfile,
        assignor,
        fundingAdmin
      } = this.props;
      fetchUserProfileMeta();
      const tagManagerArgs = {
        gtmId: 'GTM-WQXD5QP'
      };
      TagManager.initialize(tagManagerArgs);

      if (groupAdmin || admin || assignor || fundingAdmin) {
        const script = document.createElement('script');
        script.src = 'https://www.intercom.com';
        script.async = true;
        script.onload = intercomScript(userProfile);
        document.head.appendChild(script);
      }
    }
  }),
  pure
);
