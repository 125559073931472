import React from 'react';
import NodeRSA from 'node-rsa';
import { Input } from '@material-ui/core';
import { Field } from 'react-final-form';
import { Col, Row } from 'react-flexbox-grid';
import { required } from '../../../../utils/validations';
import { NoteContainer, NoteLabel, NoteWrapper, theme } from './styled-components';
import { pxToRem } from '../../../../utils/helpers';
import TitleBar from '../../TitleBar';
import {
  AlertIcon,
  PrimaryButtonWrapper,
  SubSectionTitle,
  SubSectionWrapper
} from '../../../../global-styles/global-styled-components';
import SsnInput from '../../../../utils/ssn-input';
import COLORS, { ICONS } from '../../../../utils/styles';
import Button from '../../Button';
import Logger from '../../../../utils/logger';

export default function TaxInfoStep2Form({
  taxData,
  updateTaxData,
  publicKey,
  first,
  setFirstName,
  last,
  setLastName,
  middle,
  setMiddleName,
  ssn,
  setSsn,
  isCrossEdit
}) {
  const style = { width: '100%', font: 'Roboto' };
  const rowStyle = { marginTop: pxToRem('5px'), marginLeft: pxToRem('10px') };
  const labelStyle = { ...rowStyle, fontSize: pxToRem('12px') };

  const encrypt = data => {
    try {
      const cryptoService = new NodeRSA(publicKey);
      const encrypted = cryptoService.encrypt(data, 'base64');
      return encrypted;
    } catch (err) {
      Logger.error(`tax data encryption error. ${err}`);
      return null;
    }
  };

  const setEncryptedSsn = input => {
    if (input) {
      const encrypted = encrypt(input);
      const last4 = input.substring(input.length - 4);
      return { tin: encrypted, last4 };
    }

    return null;
  };

  const setMiddleNameWraper = input => {
    const value = input && input.target && input.target.value;
    setMiddleName(value);
  };
  const setFirstNameWraper = input => {
    const value = input && input.target && input.target.value;
    setFirstName(value);
  };
  const setLastNameWraper = input => {
    const value = input && input.target && input.target.value;
    setLastName(value);
  };

  const setSsnWraper = input => {
    const value = input && input.target && input.target.value;

    // Allow only numbers, decimal points, and negative signs
    const numericValue = value.replace(/[^0-9]/g, '');

    setSsn(numericValue);
  };

  const handleSubmit = () => {
    // this is where i should be callilng updateTaxData ...
    const fullName = middle ? `${first} ${middle} ${last}` : `${first} ${last}`;
    const now = new Date().toLocaleDateString('en-US');
    const message = `Tax information Submitted by ${fullName} on ${now}`;
    const ssnPayload = setEncryptedSsn(ssn);
    const toUpdate = { tax_data: { ...taxData } };
    toUpdate.tax_data.data = {
      ...taxData.data,
      ...ssnPayload,
      first,
      last,
      middle,
      step_2_message: message
    };
    updateTaxData(toUpdate);
  };

  const formIsEmpty = () => !first || !last || !ssn || ssn.length < 9;

  return (
    <form style={style}>
      <TitleBar margin="10px 0px 0px 0px">STEP 2</TitleBar>
      <Row between="xs">
        <SubSectionWrapper xs={14} md={7}>
          <SubSectionTitle marginBottom="0">DEFINE YOUR TAX INFORMATION</SubSectionTitle>
          <Row style={labelStyle}>
            <p>Legal Name: (as reported to the IRS)</p>
          </Row>
          <Row style={rowStyle}>
            <Col xs={5} md={3}>
              <Field name="firstName">
                {({ input, meta: { error } }) => (
                  <Input
                    label="First*"
                    placeholder="First*"
                    error={!!input && !!input.value && error}
                    onChange={setFirstNameWraper}
                    value={first}
                  />
                )}
              </Field>
            </Col>{' '}
            <Col xs={5} md={3}>
              <Field name="middleName" validate={required}>
                {({ input, meta: { error } }) => (
                  <Input
                    label="Middle"
                    placeholder="Middle"
                    error={!!input && !!input.value && error}
                    onChange={setMiddleNameWraper}
                    value={middle}
                  />
                )}
              </Field>
            </Col>{' '}
            <Col xs={5} md={3}>
              <Field name="lastName">
                {({ input, meta: { error } }) => (
                  <Input
                    label="Last*"
                    placeholder="Last*"
                    error={!!input && !!input.value && error}
                    onChange={setLastNameWraper}
                    value={last}
                  />
                )}
              </Field>
            </Col>
          </Row>
          <Row style={labelStyle}>
            <p>
              Tax Identification Number:{' '}
              {taxData &&
                taxData.data &&
                taxData.data.last4 &&
                `(Submitted as: ***-**-${taxData.data.last4})`}
            </p>
          </Row>
          <Row style={labelStyle}>
            <Col>
              <SsnInput
                toggle
                style={{ margin: '5px', width: '100%', fontSize: 10 }}
                label="(SSN or ITIN)*"
                onChange={setSsnWraper}
                variant="outlined"
                value={ssn || ''}
              />
            </Col>
          </Row>
          <Row style={labelStyle}>
            <p>
              Save this information to permit Stack Officials (instead of Stripe) to generate and
              submit 1099 tax documents on your behalf.{' '}
            </p>
          </Row>
          {taxData && taxData.data && taxData.data.step_2_message && (
            <Row>
              <NoteWrapper>
                <NoteContainer>
                  {' '}
                  <AlertIcon
                    icon={ICONS.CHECK_CIRCLE_HOLLOW}
                    theme={theme}
                    color={COLORS.strongGreen}
                  />
                  <NoteLabel style={{ width: 400, padding: '8px 10px 10px 3px' }}>
                    {taxData && taxData.data.step_2_message}
                  </NoteLabel>
                </NoteContainer>
              </NoteWrapper>
            </Row>
          )}
          <Row style={rowStyle}>
            <Col>
              <PrimaryButtonWrapper left="xs" theme={theme}>
                <Button
                  type="button"
                  width="160px"
                  height="32px"
                  label="Save Tax Information"
                  disabled={isCrossEdit || !taxData || !taxData.id || formIsEmpty()}
                  primary
                  onClick={handleSubmit}
                />
              </PrimaryButtonWrapper>
            </Col>
          </Row>
        </SubSectionWrapper>
      </Row>
    </form>
  );
}
