import {
  takeEvery,
  takeLatest,
  call,
  put,
  all,
  select,
  fork,
  race,
  take,
  join
} from 'redux-saga/effects';
import { isNil, omit, path, identity, nth, equals, ifElse, always } from 'ramda';
import moment from 'moment';

import Logger from '../../utils/logger';
import {
  CREATE_ACCOUNT,
  LOGOUT,
  SET_REFERRER,
  LOGIN,
  SET_USER,
  CHANGE_PASSWORD,
  CHANGE_EMAIL,
  RESEND_EMAIL,
  UPDATE_USER_PROFILE,
  setReferrer as setReferrerAction,
  setNoAccount,
  setIsFetchingFlag,
  UPDATE_USER_ADDRESS,
  DELETE_USER_ADDRESS,
  GET_PAYEE_ACCOUNTS,
  setPayeeAccounts,
  ADD_PAYEE_ACCOUNT,
  EDIT_PAYEE_ACCOUNT,
  DELETE_PAYEE_ACCOUNT,
  setTimezonesList,
  FETCH_TIMEZONES_LIST,
  fetchTimezonesList as fetchTimezonesListAction,
  setGroups,
  setNewGroup,
  FETCH_GROUPS,
  CREATE_GROUP,
  FETCH_EVENT_BILLING_STATEMENTS,
  setPastEventBillingStatements,
  setDueDatetEventBillingStatements,
  setRecentEventBillingStatements,
  setIsFetchingFlagPastStatement,
  setIsFetchingFlagDueDateStatement,
  setIsFetchingFlagRecentStatement,
  setIsFetchingGroup,
  setGroupInfo,
  setGroupUsers,
  fetchGroupInfo as fetchGroupInfoAction,
  FETCH_GROUP_INFO,
  UPDATE_GROUP_INFO,
  setGroupSubscriptions,
  setSelectedRole as setSelectedRoleAction,
  STORE_SELECTED_ROLE,
  FETCH_GROUPS_REPORT,
  FETCH_RSA_PUB,
  setRsaPubSaga,
  fetchRsaPubSaga
} from './index';
import {
  selectUserId,
  getUserProfileEmail,
  hasNoAccount as hasNoAccountSelector,
  getUserIndex,
  getPayeeId,
  selectProfileUserId,
  isAnyEventAssignorOrAssessorOrOfficial
} from './selectors';
import { history } from '../store';
import * as paths from '../paths';
import {
  userIdxGetter,
  createUserStorage,
  getCurrentStorage,
  setTokens,
  clearEverything,
  setReferrer as setReferrerInStore,
  removeReferrer,
  getReferrer as getRefererFromStore,
  setUser,
  getGamesEvent,
  getPaymentsEvent,
  getRefreshToken,
  getAccessToken,
  getReportsEvent,
  getPeopleEvent,
  persistSelectedRole
} from '../../utils/auth';
import Api from '../../utils/api-wrapper';
import { addNotifications, setNotificationCounts } from '../Notifications';
import {
  delay,
  sInterpolator,
  undefinedToNull,
  sortListByKey,
  formatGroupInfo
} from '../../utils/helpers';
import { mergeData } from '../../utils/parsers';
import { storeGamesEvent } from '../Games';
import { storeReportsEvent } from '../Reports';
import { storePaymentsEvent } from '../Payments';
import { SET_ACTIVE_USER_INFO, storePeopleEvent } from '../User';
import { errorDecorate } from '../Progress/helper';
import { EVENT_BILLING_STATEMENT_TYPES, INVOICE_STATUS } from '../../constants';
import { hasPaymentProfileRequired } from '../User/selectors';
import { fetchUserProfileMeta } from '../User/sagas';

export function* createNewUser({
  payload: {
    values: {
      firstName,
      lastName,
      email,
      isSuperAdmin = false,
      userRole,
      groupId,
      isGroupAdmin = false
    },
    resolve
  }
}) {
  try {
    yield call(isSuperAdmin || isGroupAdmin ? Api.createNewAdmin : Api.createNewUser, {
      first_name: firstName,
      last_name: lastName,
      email,
      user_role: (isSuperAdmin || isGroupAdmin) && userRole,
      group_id: (isSuperAdmin || isGroupAdmin) && groupId
    });
    yield call(resolve);
    if (isSuperAdmin || isGroupAdmin) {
      yield put(addNotifications([{ type: 'success', message: 'New user has been created' }]));
    } else {
      yield call(history.push, paths.SUCCESS);
    }
  } catch (e) {
    Logger.error(e.message);
    yield call(resolve);
    yield put(addNotifications(e.message));
  }
}

export function* logout() {
  try {
    const users = yield call(getCurrentStorage);
    yield all(users.map(({ idToken }) => call(Api.logout, { token: idToken })));
  } catch (e) {
    Logger.appLog({ error: e }, { payload: 'Error in logout.' });
  } finally {
    yield call(clearEverything);
    window.location.href = process.env.REACT_APP_MARKETING_URL;
  }
}

export function* getUserProfile(
  params = {
    token: undefined,
    usrIdx: undefined,
    userProfileData: undefined,
    userId: undefined,
    updated: false
  }
) {
  yield put(setIsFetchingFlag(true));
  const { token, usrIdx, userProfileData, userId, updated } = params;
  const {
    data: { attributes, id }
  } = yield call(
    ifElse(isNil, () => Api.getUserProfile({ token, userId }), always({ data: userProfileData })),
    userProfileData
  );

  const payload = attributes;
  if (usrIdx) {
    payload.userIndex = usrIdx;
  }

  if (!userId || updated)
    yield put({ type: SET_USER, payload: { ...attributes, id, userIndex: usrIdx } });
  if (!userId) {
    yield call(setUser, {
      user: {
        ...omit(
          [
            'admin_events',
            'assessor_events',
            'assignor_events',
            'official_events',
            'admin',
            'super_admin',
            'notifications'
          ],
          attributes
        ),
        id
      },
      usrIdx
    });
    yield put(setNotificationCounts(attributes.notifications));
  } else {
    const {
      data: { attributes: attr, id: userid }
    } = yield call(Api.fetchActiveUserInfo, {
      userId
    });
    yield put({ type: SET_ACTIVE_USER_INFO, payload: { ...attr, id: userid } });
  }
  yield put(setIsFetchingFlag(false));
  const gamesEvent = yield call(getGamesEvent, { usrIdx });
  const reportsEvent = yield call(getReportsEvent, { usrIdx });
  const paymentsEvent = yield call(getPaymentsEvent, { usrIdx });
  const peopleEvent = yield call(getPeopleEvent, { usrIdx });
  yield put(storeGamesEvent({ ...gamesEvent, usrIdx }));
  yield put(storeReportsEvent({ ...reportsEvent, usrIdx }));
  yield put(storePaymentsEvent({ ...paymentsEvent, usrIdx }));
  yield put(storePeopleEvent({ id: peopleEvent, usrIdx }));
  yield put(fetchTimezonesListAction({ token }));
  yield put(fetchRsaPubSaga());
}

export const ALMOST_A_SECOND = 900;

export function* handleStoreRefresh({ usrIdx }) {
  const storeEmail = yield select(getUserProfileEmail);
  if (isNil(storeEmail)) {
    const userIndex = isNil(usrIdx) ? yield call(userIdxGetter) : usrIdx;
    yield call(getUserProfile, { usrIdx: userIndex });

    // TODO: cleanup(redux state saga)
    // try {
    //   const { id } = yield select(getUser);
    // const response = yield call(Api.fetchUserAssignments, {
    //   id,
    //   start_date: moment().format('YYYY-MM-DD'),
    //   end_date: moment().add(1, 'day').format('YYYY-MM-DD')
    // });
    // yield put(setAssignments(response.data));
    // yield put(setCurrentDayAssignmentCount(response.data.length));
    // } catch (err) {
    //   Logger.error(err);
    // }
  }
}

export function* singleAuthorizeLoop({ refreshToken, user, idToken, userIndex, storeRefreshfn }) {
  const email = path(['profile', 'email'], user);

  const data = yield call(Api.refreshTokens, {
    data: { email, token: refreshToken },
    token: idToken
  });

  const token = mergeData(data);
  yield call(setTokens, {
    tokens: { accessToken: token.access_token, idToken: token.id_token, refreshToken },
    usrIdx: userIndex
  });

  yield call(storeRefreshfn, { usrIdx: userIndex, token: idToken });

  return token;
}

export function* authorizeLoop({ usrIdx }) {
  const users = yield call(getCurrentStorage);
  try {
    while (true) {
      const tokens = yield all(
        users.map(({ user, idToken, refreshToken: uToken }, uIdx) =>
          call(singleAuthorizeLoop, {
            user,
            idToken,
            refreshToken: uToken,
            userIndex: uIdx,
            storeRefreshfn: uIdx === usrIdx ? handleStoreRefresh : identity
          })
        )
      );
      const token = nth(usrIdx, tokens);
      yield call(delay, token.expires_in * ALMOST_A_SECOND);
    }
  } catch (e) {
    const hasNoAccount = yield select(hasNoAccountSelector);
    if (hasNoAccount) {
      yield call(history.push, paths.CREATE_ACCOUNT);
    } else {
      yield put({ type: LOGOUT });
    }
  }
}
export function* login({ usrIdx }) {
  try {
    const referer = getRefererFromStore();
    yield put(setReferrerAction());
    const toUrl = sInterpolator({ userIdx: usrIdx }, paths.MAIN);
    yield call(history.push, referer || toUrl);
    // Store login dependant data in the same transaction
  } catch (e) {
    Logger.appLog({ error: e }, { payload: `Error during login: ${usrIdx}` });
    yield put(addNotifications(e.message));
    yield put({ type: LOGOUT });
  }
}

export function* deduceUserProfileAndIndex(loginPayload) {
  try {
    const {
      data: { attributes, id }
    } = yield call(Api.getUserProfile, {
      token: loginPayload.idToken
    });

    const currentStorage = yield call(getCurrentStorage);
    const existingUserIdx = currentStorage.findIndex(single => {
      if (single.user) {
        return equals(single.user.profile.email, attributes.profile.email);
      }
      return false;
    });

    if (existingUserIdx !== -1) {
      yield call(getUserProfile, {
        usrIdx: existingUserIdx,
        token: loginPayload.idToken,
        userProfileData: { attributes, id }
      });
      return existingUserIdx;
    }
    const userIndex = yield call(createUserStorage, loginPayload);
    yield call(getUserProfile, { usrIdx: userIndex, token: loginPayload.idToken });

    // TODO: cleanup(redux state saga)
    // try {
    // const response = yield call(Api.fetchUserAssignments, {
    //   id,
    //   start_date: moment().format('YYYY-MM-DD'),
    //   end_date: moment().add(1, 'day').format('YYYY-MM-DD'),
    //   options: { token: loginPayload.idToken }
    // });
    // yield put(setAssignments(response.data));
    // yield put(setCurrentDayAssignmentCount(response.data.length));
    // } catch (err) {
    //   Logger.error(err);
    // }

    return userIndex;
  } catch (err) {
    Logger.appLog(
      { error: err },
      { payload: `In deduceUserProfileAndIndex Error for loginPayload ${loginPayload}` }
    );
    if (err.status === '404') yield put(setNoAccount(true));
    return err;
  }
}

export function* authorization() {
  let userIndex = yield call(userIdxGetter);

  const storedToken = yield call(getRefreshToken, { usrIdx: userIndex });

  while (true) {
    if (!storedToken || isNil(userIndex)) {
      const { payload } = yield take(LOGIN);
      userIndex = yield call(deduceUserProfileAndIndex, payload);
      const loggingIn = yield fork(login, { ...payload, usrIdx: userIndex });
      yield join(loggingIn);
    }

    const authLoopTask = yield fork(authorizeLoop, { usrIdx: userIndex });

    const { signOutAction } = yield race({
      signOutAction: take(LOGOUT),
      authLoop: join(authLoopTask)
    });

    if (signOutAction) {
      yield call(logout);
      return;
    }
  }
}

export function* setReferrer({ payload }) {
  try {
    if (payload) {
      yield call(setReferrerInStore, payload);
    } else {
      yield call(removeReferrer);
    }
  } catch (e) {
    Logger.error(e);
  }
}

export function* changePassword({ payload: { old_password, new_password, resolve } }) {
  try {
    yield put(setIsFetchingFlag(true));
    const userId = yield select(selectUserId);
    const usrIdx = yield select(getUserIndex);
    const access_token = yield call(getAccessToken, { usrIdx });
    yield call(Api.changePassword, { userId, old_password, new_password, access_token });
    yield getUserProfile();
    yield call(resolve);
    yield put(addNotifications([{ type: 'success', message: 'Password changed' }]));
  } catch (e) {
    Logger.error(e.message);
    yield put(addNotifications(e.message));
  } finally {
    yield put(setIsFetchingFlag(false));
  }
}

export function* changeEmail({ payload: { email, password, isActive, resolve } }) {
  try {
    yield put(setIsFetchingFlag(true));
    const userId = yield select(selectUserId);
    yield !isActive && call(Api.updateUser, { userId, active: true });
    yield call(Api.changeEmail, { userId, email, password });
    yield getUserProfile();
    const {
      data: { attributes: attr, id: userid }
    } = yield call(Api.fetchActiveUserInfo, {
      userId
    });
    yield put({ type: SET_ACTIVE_USER_INFO, payload: { ...attr, id: userid } });
    yield call(resolve);
    yield put(addNotifications([{ type: 'success', message: 'Email changed' }]));
  } catch (e) {
    Logger.error(e.message);
    yield put(addNotifications(e.message));
  } finally {
    yield put(setIsFetchingFlag(false));
  }
}

export function* resendEmail() {
  try {
    yield put(setIsFetchingFlag(true));
    const userId = yield select(selectUserId);
    yield call(Api.resendEmail, { userId });
    yield getUserProfile();
    yield put(
      addNotifications([
        { type: 'success', message: 'Verification email has been sent to your email account' }
      ])
    );
  } catch (e) {
    Logger.error(e.message);
    yield put(addNotifications(e.message));
  } finally {
    yield put(setIsFetchingFlag(false));
  }
}

export function* updateUserProfile({ payload: { values, pathname } }) {
  try {
    const {
      profile,
      emergencyInfo,
      primaryAddress,
      primaryContact,
      travelAddress,
      parentGuardianContact
    } = values;
    let userId = null;
    userId = yield select(selectUserId);
    if (!userId) {
      userId = yield select(selectProfileUserId);
    }
    const isPayee = yield select(isAnyEventAssignorOrAssessorOrOfficial);
    const isPaymentProfileRequired = yield select(hasPaymentProfileRequired);

    if (profile.dob) profile.dob = moment(profile.dob).format('YYYY-MM-DD');

    yield put(setIsFetchingFlag(true));

    const travelInfo = primaryAddress.makeTravelAddress ? { ...primaryAddress } : travelAddress;
    travelInfo.type_id = 2;
    delete travelInfo.id;

    yield call(Api.updateUserProfile, {
      userId,
      profile: undefinedToNull(profile),
      emergencyInfo: undefinedToNull(emergencyInfo),
      primaryContact: undefinedToNull(primaryContact),
      parentGuardianContact: undefinedToNull(parentGuardianContact),
      primaryAddress: undefinedToNull(primaryAddress),
      travelInfo: undefinedToNull(travelInfo)
    });

    if (
      sInterpolator({ userIdx: userIdxGetter(), userId }, paths.START_PROFILE_INFORMATION) ===
      pathname
    ) {
      yield call(fetchUserProfileMeta, { payload: { userId } });
      if (isPayee && isPaymentProfileRequired) {
        yield call(
          history.push,
          sInterpolator({ userIdx: userIdxGetter(), userId }, paths.START_PROFILE_PAYMENT_METHOD)
        );
      } else {
        yield call(history.push, sInterpolator({ userIdx: userIdxGetter() }, paths.MAIN));
      }
    }
  } catch (e) {
    yield put(addNotifications(e.message));
    Logger.error(e);
  } finally {
    let userId = null;
    userId = yield select(selectUserId);
    if (!userId) {
      userId = yield select(selectProfileUserId);
    }
    if (
      sInterpolator({ userIdx: userIdxGetter(), userId }, paths.START_PROFILE_INFORMATION) ===
      pathname
    ) {
      yield getUserProfile({ userId, updated: true });
    } else {
      yield getUserProfile({ userId, updated: false });
    }
    yield put(setIsFetchingFlag(false));
  }
}

export function* updateUserAddress({ payload: { userId, currentAddress, newAddress } }) {
  try {
    if (currentAddress.type_id !== newAddress.type_id) {
      if (currentAddress.type_id) {
        yield call(Api.createUserAddresses, {
          userId,
          ...currentAddress
        });
      }
      yield call(Api.createUserAddresses, {
        userId,
        ...newAddress
      });
      yield getUserProfile({ userId });
    }
  } catch (err) {
    yield put(addNotifications(err.message));
  } finally {
    yield put(setIsFetchingFlag(false));
  }
}

export function* deleteUserAddress({ payload: { userId, id } }) {
  try {
    yield call(Api.deleteUserAddress, {
      userId,
      id
    });
    yield getUserProfile({ userId });
  } catch (err) {
    yield put(addNotifications(err.message));
  } finally {
    yield put(setIsFetchingFlag(false));
  }
}

export function* getPayeeAccounts() {
  try {
    const id = yield select(getPayeeId);
    let data = [];
    if (id) {
      data = yield call(Api.getPayeeAccounts, { id });
    }
    yield put(setPayeeAccounts(data.data));
  } catch (err) {
    yield put(addNotifications(err.message));
  } finally {
    yield put(setIsFetchingFlag(false));
  }
}

export function* addPayeeAccount({ payload: { accountName, autoRequestFunds } }) {
  try {
    const account = yield call(Api.addPayeeAccount, {
      data: {
        account_name: accountName,
        auto_deposit: autoRequestFunds,
        redirect: window.location.href
      }
    });
    yield put(setIsFetchingFlag(true));
    window.location.href = account.enrollAccountLink;
  } catch (err) {
    yield put(addNotifications(err.message));
    yield put(setIsFetchingFlag(false));
  }
}

export function* editPayeeAccount({ payload: { id, accountName, autoRequestFunds } }) {
  try {
    const account = yield call(Api.editPayeeAccount, {
      id,
      data: {
        account_name: accountName,
        auto_deposit: autoRequestFunds,
        redirect: window.location.href
      }
    });
    yield put(setIsFetchingFlag(true));
    window.open(account.enrollAccountLink);
  } catch (err) {
    yield put(addNotifications(err.message));
    yield put(setIsFetchingFlag(false));
  }
}

export function* deletePayeeAccount({ payload: { userId, id } }) {
  try {
    yield call(Api.deletePayeeAccount, {
      id
    });
    yield getPayeeAccounts({ payload: userId });
  } catch (err) {
    yield put(addNotifications(err.message));
  } finally {
    yield put(setIsFetchingFlag(false));
  }
}

export function* processFetchTimezonesList(payload) {
  const response = yield call(Api.fetchTimezonesList, payload);
  if (response)
    yield put(
      setTimezonesList(
        response.map(item => ({
          ...item,
          value: item.code
        }))
      )
    );
}

export function* fetchTimezonesList({ payload }) {
  yield errorDecorate(processFetchTimezonesList, payload);
}

export function* fetchGroups() {
  try {
    yield put(setIsFetchingFlag(true));
    const groups = yield call(Api.fetchGroups);
    yield put(setGroups(sortListByKey(mergeData(groups))));
  } catch (e) {
    if (parseInt(e.status, 10) !== 403) {
      yield put(addNotifications(e));
    }
  } finally {
    yield put(setIsFetchingFlag(false));
  }
}

export function* fetchGroupsReport({ payload: { resolve = () => true, reject = () => true } }) {
  try {
    yield put(setIsFetchingFlag(true));
    const groups = yield call(Api.fetchGroupsReport);
    if (groups) {
      yield call(resolve, groups);
    }
  } catch (e) {
    if (parseInt(e.status, 10) !== 403) {
      yield call(reject, e);
      yield put(addNotifications(e.message));
    }
  } finally {
    yield put(setIsFetchingFlag(false));
  }
}
export function* createGroup({
  payload: {
    resolve,
    values: { isSuperAdmin, groupName: name, parentId: parent_id }
  }
}) {
  try {
    if (isSuperAdmin) {
      // ECOM:2857 - all group created via the UI will be test groups
      const response = yield call(Api.createGroup, { name, parent_id, is_test: 1 });
      if (response) {
        yield put(setNewGroup(mergeData(response)));
        yield put(addNotifications([{ type: 'success', message: 'New group has been created' }]));
      }
    }
  } catch (err) {
    yield put(addNotifications(err.message));
  } finally {
    yield call(resolve);
  }
}

export function* processfetchEventBillingStatements({ type, platformId }) {
  const dateFormat = 'YYYY-MM-DD HH:mm:ss';
  switch (type) {
    case EVENT_BILLING_STATEMENT_TYPES[0]:
      {
        yield put(setIsFetchingFlagPastStatement(true));
        const pastStatements = yield call(Api.fetchEventBillingStatements, {
          endDate: moment().format(dateFormat),
          status: INVOICE_STATUS[0],
          platformId
        });
        yield put(setPastEventBillingStatements(mergeData(pastStatements)));
        yield put(setIsFetchingFlagPastStatement(false));
      }
      break;
    case EVENT_BILLING_STATEMENT_TYPES[1]:
      {
        yield put(setIsFetchingFlagDueDateStatement(true));
        const dueDateStatements = yield call(Api.fetchEventBillingStatements, {
          startDate: moment().format(dateFormat),
          endDate: moment().endOf('month').format(dateFormat),
          status: INVOICE_STATUS[0],
          platformId
        });
        yield put(setDueDatetEventBillingStatements(mergeData(dueDateStatements)));
        yield put(setIsFetchingFlagDueDateStatement(false));
      }
      break;
    case EVENT_BILLING_STATEMENT_TYPES[2]:
      {
        yield put(setIsFetchingFlagRecentStatement(true));
        const recentStatements = yield call(Api.fetchEventBillingStatements, {
          endDate: moment().format(dateFormat),
          status: INVOICE_STATUS[1],
          platformId
        });
        yield put(setRecentEventBillingStatements(mergeData(recentStatements)));
        yield put(setIsFetchingFlagRecentStatement(false));
      }
      break;
    default:
  }
}

export function* fetchEventBillingStatements({ payload }) {
  yield errorDecorate(processfetchEventBillingStatements, payload);
}

export function* fetchGroupInfo({ payload: { groupId } }) {
  try {
    yield put(setIsFetchingGroup(true));
    const response = yield call(Api.fetchGroupInfo, { id: groupId });
    if (response) {
      const { users, groupInfo, subscriptions } = yield call(formatGroupInfo, mergeData(response));
      yield put(setGroupInfo(groupInfo));
      yield put(setGroupUsers(users));
      yield put(setGroupSubscriptions(subscriptions));
    }
  } catch (err) {
    yield put(addNotifications(err.message));
  } finally {
    yield put(setIsFetchingGroup(false));
  }
}

export function* updateGroupInfo({
  payload: {
    groupId,
    isSuperAdmin,
    fee,
    product,
    subscriptionStatus,
    custId,
    netsuiteId,
    billingStartDate,
    invoiceRunDay,
    ...data
  }
}) {
  try {
    yield put(setIsFetchingGroup(true));
    const { updatedGroup, updatedFee, updatedStatus, updatedCustId } = yield all({
      updatedGroup: call(Api.updateGroupInfo, { id: groupId, ...data }),
      updatedFee:
        isSuperAdmin && fee
          ? call(Api.updateSubscriptionFee, { platformId: groupId, fee })
          : { success: true },
      updatedStatus:
        isSuperAdmin && product
          ? call(Api.updatePlatformSubscription, {
              platformId: groupId,
              product,
              active: subscriptionStatus,
              billing_start_date: billingStartDate,
              invoice_generation_day: invoiceRunDay
            })
          : { success: true },
      updatedCustId:
        isSuperAdmin && custId
          ? call(Api.updatePlatformSubscriptionHeader, {
              platformId: groupId,
              netsuite_id: netsuiteId,
              salesforce_id: custId
            })
          : { success: true }
    });
    if (updatedGroup && updatedFee && updatedStatus && updatedCustId) {
      yield put(
        addNotifications([{ type: 'success', message: 'Successfully updated the group info' }])
      );
      yield put(fetchGroupInfoAction({ groupId }));
    }
  } catch (err) {
    yield put(addNotifications(err.message));
  } finally {
    yield put(setIsFetchingGroup(false));
  }
}

export function* storeSelectedRole({ payload: { role, usrIdx = userIdxGetter() } }) {
  yield call(persistSelectedRole, { role, usrIdx });
  yield put(setSelectedRoleAction(role));
}

export function* processFetchRSAKey() {
  try {
    const response = yield call(Api.getRSAKey);
    if (response) {
      // rsa keys returned by the api escape new lines so we have to undo
      const formatted = response.split('\\n').join('\n');
      yield put(setRsaPubSaga(formatted));
    }
  } catch (err) {
    // dont blow up on a 404
    yield put(setRsaPubSaga(null));
  }
}

export function* fetchRSAKeyApi({ payload }) {
  yield errorDecorate(processFetchRSAKey, payload);
}

export const authSagas = [
  takeEvery(CREATE_ACCOUNT, createNewUser),
  takeEvery(SET_REFERRER, setReferrer),
  fork(authorization),
  takeLatest(UPDATE_USER_PROFILE, updateUserProfile),
  takeEvery(CHANGE_PASSWORD, changePassword),
  takeEvery(CHANGE_EMAIL, changeEmail),
  takeEvery(RESEND_EMAIL, resendEmail),
  takeLatest(UPDATE_USER_ADDRESS, updateUserAddress),
  takeLatest(DELETE_USER_ADDRESS, deleteUserAddress),
  takeLatest(GET_PAYEE_ACCOUNTS, getPayeeAccounts),
  takeLatest(ADD_PAYEE_ACCOUNT, addPayeeAccount),
  takeLatest(EDIT_PAYEE_ACCOUNT, editPayeeAccount),
  takeLatest(DELETE_PAYEE_ACCOUNT, deletePayeeAccount),
  takeLatest(FETCH_TIMEZONES_LIST, fetchTimezonesList),
  takeLatest(FETCH_GROUPS, fetchGroups),
  takeLatest(FETCH_GROUPS_REPORT, fetchGroupsReport),
  takeLatest(CREATE_GROUP, createGroup),
  takeLatest(FETCH_GROUP_INFO, fetchGroupInfo),
  takeLatest(UPDATE_GROUP_INFO, updateGroupInfo),
  takeEvery(FETCH_EVENT_BILLING_STATEMENTS, fetchEventBillingStatements),
  takeLatest(STORE_SELECTED_ROLE, storeSelectedRole),
  takeLatest(FETCH_RSA_PUB, fetchRSAKeyApi)
];

export default authSagas;
