import styled from 'styled-components';
import { Col } from 'react-flexbox-grid';
import Icon from '../views/components/Icon';
import COLORS, { mediaQueries } from '../utils/styles';
import { pxToRem } from '../utils/helpers';

// ******* default styled classes used by the application
// ******* define component controls in the components styled-component file if you need to
// ******* define custom styles for component controls

export const AlertIcon = styled(Icon)`
  cursor: ${({ theme }) => theme.icon && theme.icon.cursor};
  margin-top: ${({ theme }) => theme.icon && theme.icon.margin_top};
  margin-left: ${({ theme }) => theme.icon && theme.icon.margin_left};
  margin-right: ${({ theme }) => theme.icon && theme.icon.margin_right};
  float: ${({ theme }) => theme.icon && theme.icon.float};
  padding-right: ${({ theme }) => theme.icon && theme.icon.padding_right};
  height: ${({ theme }) => theme.icon && theme.icon.height};
  width: ${({ theme }) => theme.icon && theme.icon.width};
  ${({ customStyle }) => customStyle}
`;

export const Wrapper = styled.div`
  display: flex;
  font-family: ${({ theme }) => theme.div && theme.div.font_family};
  font-size: ${({ theme }) => theme.div && theme.div.font_size};
  font-weight: ${({ theme }) => theme.div && theme.div.font_weight};
  text-align: ${({ theme }) => theme.div && theme.div.text_align};
  color: ${({ theme }) => theme.div && theme.div.color};
  border: ${({ theme }) => theme.div && theme.div.border};
  border-radius: ${({ theme }) => theme.div && theme.div.border_radius};
  border-color: ${({ theme }) => theme.div && theme.div.border_color};
  line-height: ${({ theme }) => theme.div && theme.div.line_height};
  padding-left: ${({ theme }) => theme.div && theme.div.padding_left};
  box-shadow: ${({ theme }) => theme.div && theme.div.box_shadow};
  width: ${({ theme }) => theme.div && theme.div.width};
  ${({ customStyle }) => customStyle}
`;

export const Title = styled.h3`
  margin-left: ${({ theme }) => theme.title && theme.title.margin_left};
  padding-right: ${({ theme }) => theme.title && theme.title.padding_right};
  ${({ customStyle }) => customStyle}
`;

export const FormTitle = styled.span`
  color: ${COLORS.sapphire};
  text-transform: uppercase;
  font-size: 0.85rem;
  font-size: ${pxToRem('15px')};
  font-weight: 500;
  letter-spacing: 0.5px;
  ${({ customStyle }) => customStyle}
`;

export const SubSectionWrapper = styled(Col)`
  padding-left: ${pxToRem('20px')};
`;

export const SubSectionTitle = styled.h4`
  color: ${COLORS.denimBlue};
  font-size: ${pxToRem('15px')};
  text-transform: capitalize;
  font-weight: bold;
  letter-spacing: 0.5px;
  margin: 1rem 0 1.5rem 0.5rem;
  margin-top: 1rem;
  margin-left: 0;
  margin-bottom: ${({ marginBottom }) => marginBottom || '1.5rem'}
  margin-right: 0.5rem
  text-transform: uppercase;
  ${mediaQueries.sm(`
  margin: 1rem 0 0rem 0.5rem;
  `)};
  ${({ customStyle }) => customStyle}
`;

export const Description = styled.p`
  margin-left: ${({ theme }) => theme.description && theme.description.margin_left};
  padding-right: ${({ theme }) => theme.description && theme.description.padding_right};
  ${({ customStyle }) => customStyle}
`;

export const PrimaryButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${({ theme }) => theme.primary_button && theme.primary_button.margin_bottom};
  margin-right: ${({ theme }) => theme.primary_button && theme.primary_button.margin_right};
  ${({ customStyle }) => customStyle}
`;
