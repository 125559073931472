import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import Button from '../../../Button';
import Modal from '../../../Modal';
import {
  TaxInfoWrapper,
  ButtonWrapper,
  FieldWrapper,
  HalfContent,
  FormFields
} from './styled-components';

/**
 * used to determine if the set button should be displayed or not. true if displayable, else false
 * @param {*} payerInfo the form data
 * @param {*} submitting is the form being submitted
 * @param {*} invalid did any form fields fail the defailed validation
 * @param {*} pristine was there any data in the form that was changed
 * @returns true if displayable, else false
 */
const shouldDisable = (payerInfo, submitting, invalid, pristine) => {
  // if payerinfo is not null
  // check if form data has changed aka pristine
  // if pristine return true
  // else
  // check if invalid or submitting
  // if payerinfo is null
  // return true if pristine or invalid or submitting
  if (payerInfo != null) {
    if (pristine) return false;
    return submitting || invalid;
  }
  return pristine || submitting || invalid;
};

const EventSettingModal = ({
  onClose,
  onSubmitManually,
  fields,
  title,
  initialValues,
  openModal,
  payerInfo
}) => {
  const [formData, setFormData] = useState({ ...initialValues, ...payerInfo });
  useEffect(() => {
    setFormData({ ...initialValues, ...payerInfo });
  }, [payerInfo, initialValues]);

  return (
    <Modal
      title={title}
      onClose={() => onClose(formData)}
      size={{
        xs: 8,
        sm: 6,
        md: 6,
        lg: 4
      }}
      titleStyle={{
        textAlign: 'left',
        fontSize: '16px',
        marginRight: '50px'
      }}
    >
      <TaxInfoWrapper>
        <Form
          onSubmit={values => {
            onSubmitManually(values);
            if (!payerInfo) {
              onClose();
            }
          }}
          initialValues={{ ...formData }}
          render={({ handleSubmit, pristine, submitting, invalid }) => (
            <form onSubmit={handleSubmit}>
              <FormFields>
                {fields({ openModal, hasInvoice: initialValues && initialValues.has_invoice }).map(
                  ({ field }) =>
                    field.colSpan ? (
                      <HalfContent margin={field.margin} width={field.colSpan}>
                        {' '}
                        <Field {...field} />
                      </HalfContent>
                    ) : (
                      <FieldWrapper>
                        <Field {...field} />
                      </FieldWrapper>
                    )
                )}
              </FormFields>
              <ButtonWrapper>
                <Button
                  type="submit"
                  label={payerInfo ? 'Set' : 'Add'}
                  customStyle={{ textTransform: 'capitalize' }}
                  disabled={shouldDisable(payerInfo, submitting, invalid, pristine)}
                  primary
                />
              </ButtonWrapper>
            </form>
          )}
        />
      </TaxInfoWrapper>
    </Modal>
  );
};

EventSettingModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmitManually: PropTypes.func.isRequired
};

export default EventSettingModal;
