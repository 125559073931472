import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

/*
 ***SOURCE: https://codesandbox.io/p/sandbox/material-ui-ssn-input-d6fir?file=%2FssnInput.js%3A1%2C1-76%2C1
 */

const formatSsn = (str, show) => {
  return str
    .split('')
    .map((l, i) => {
      if (i === 2 || i === 4) return show ? `${l}-` : '*-';
      if (i < 5 && !show) return '*';
      return l;
    })
    .join('');
};

const SsnInput = props => {
  const {
    toggle = false,
    value: ssn = undefined,
    onChange = null,
    InputProps = {},
    ...other
  } = props;
  const [text, setText] = useState(ssn || '');
  const [show, setShow] = useState(false);
  const val = formatSsn(ssn === undefined ? text : ssn, show);

  const update = (name, value) => {
    setText(value);
    if (onChange) {
      onChange({ target: { name, value } });
    }
  };

  const keyChange = e => {
    const {
      keyCode,
      key,
      target: { name }
    } = e;
    // if tab or enter key
    if ([9, 13].includes(keyCode)) return;
    // if backspace
    if (keyCode === 8 && text.length) {
      update(name, text.slice(0, -1));
    }
    // if not a number
    if (!/^\d+$/.test(key)) return;
    // if at 9 digits
    if (text.length === 9) return;
    update(name, text + key);
  };

  return (
    <TextField
      {...other}
      value={val}
      onKeyDown={keyChange}
      InputProps={{
        ...InputProps,
        endAdornment: toggle && (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle ssn visibility"
              onClick={() => setShow(!show)}
              onMouseDown={e => e.preventDefault()}
            >
              {show ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};

export default SsnInput;
