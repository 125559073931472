import React, { useState } from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { InputsContainer, TitleBar, HalfContent, SectionContainer } from '../styled-components';
import config from './config';
import TaxInfoModal from '../Modal';
import LoaderWrapper from '../../../LoaderWrapper';
import Enhancer from '../../../../containers/Event/EventInformation/Modal';

const TaxesSection = ({
  billingMethodId,
  billingCustomersList,
  updateBillingCustomer,
  onAddBillingCustomer,
  billingCustomerIsFetching,
  mutators,
  isGamesEventGroupAdmin,
  formValues,
  hasStagingBatch,
  initialValue,
  isFetchingBillingCustomerList
}) => {
  const [eventBillingModal, setEventBillingModal] = useState(false);
  const { tax_info } = formValues;
  const [isUpdateTaxInfoModalOpen, setUpdateTaxInfoModal] = useState(false);
  const [updatedBillingCustomerList = billingCustomersList, setNewList] = useState();
  const [updatedTaxInfo, setUpdatedTaxInfo] = useState(null); // Updated state
  const modalClose = () => {
    setEventBillingModal(false);
    mutators.setTaxInfoDropdown(tax_info);
  };
  const modalCloseUpdatePayer = () => {
    setUpdateTaxInfoModal(false);
    setUpdatedTaxInfo(null);
    mutators.setTaxInfoDropdown(initialValue);
  };

  const onTaxInfoUpdate = user => {
    /*
      Dev notes: a better way to do this is to not mutate the param and instead set the correct value
      when the object is composed. this didn't work because of the way we send field values to the framework
      and it is the framework that decides how to compose the resulting object. Maybe someone smarter than
      me can figure this one out
     */
    /* eslint-disable no-param-reassign */
    user.state = (user.state && user.state.value) || user.state;
    updateBillingCustomer({
      data: { ...user },
      platformId: user.groupBillingCustomer[0].platform_id,
      customerId: user.id
    });
  };

  const handleUpdateTaxInfo = updatedInfo => {
    setUpdatedTaxInfo(updatedInfo);
    onTaxInfoUpdate(updatedInfo);
    setUpdateTaxInfoModal(false);
    setNewList(
      updatedBillingCustomerList.map(user => (user.id === updatedInfo.id ? updatedInfo : user))
    );
  };

  return (
    <>
      <SectionContainer>
        <TitleBar>Paying your Officials</TitleBar>
        <LoaderWrapper isFetching={billingCustomerIsFetching || isFetchingBillingCustomerList}>
          {config
            .getTaxesFields({
              billingMethodId,
              billingCustomers: updatedBillingCustomerList,
              setEventBillingModal,
              mutators,
              isGamesEventGroupAdmin,
              tax_info,
              hasStagingBatch,
              setUpdateTaxInfoModal,
              setUpdatedTaxInfo
            })
            .map(({ field }) => (
              <InputsContainer key={field.name}>
                <HalfContent>
                  <Field {...field} />
                </HalfContent>
              </InputsContainer>
            ))}
        </LoaderWrapper>
      </SectionContainer>
      {eventBillingModal && (
        <TaxInfoModal
          title="Add New Payer Details"
          billingMethodId={billingMethodId}
          onClose={modalClose}
          onSubmitManually={onAddBillingCustomer}
          fields={config.taxPayerModalFields}
          openModal={eventBillingModal}
        />
      )}
      {isUpdateTaxInfoModalOpen && (
        <TaxInfoModal
          title="Payer Details"
          onClose={modalCloseUpdatePayer}
          onSubmitManually={values => handleUpdateTaxInfo(values)}
          fields={config.taxPayerModalFields}
          openModal={isUpdateTaxInfoModalOpen}
          updateBillingCustomer={updateBillingCustomer}
          payerInfo={updatedTaxInfo}
        />
      )}
    </>
  );
};

TaxesSection.propTypes = {
  isFetchingBillingCustomerList: PropTypes.bool.isRequired,
  billingMethodId: PropTypes.number.isRequired,
  taxInfo: PropTypes.objectOf(Object)
};

TaxesSection.defaultProps = {
  taxInfo: {}
};

export default Enhancer(TaxesSection);
